<div class="card card-success">
    <div class="card-header">
        <h3 class="card-title">Filtros</h3>
        <div class="card-tools">
            <!-- <button type="button" class="btn btn-tool" (click)="collapse.toggle()" [attr.aria-expanded]="!gfg"
                aria-controls="collapseExample">
               
                <i *ngIf="!gfg" class="fas fa-minus"></i>
                <i *ngIf="gfg"  class="fas fa-plus"></i>
               
            </button>-->
            <button class="btn btn-primary float-right " shape="round" (click)="collapse.toggle()"
                [attr.aria-expanded]="!gfg" aria-controls="collapseExample">
                <span *ngIf="gfg">Mostrar filtros</span>
                <span *ngIf="!gfg">Esconder filtros</span>
            </button>
        </div>

    </div>

    <div class="card-body" #collapse="ngbCollapse" [(ngbCollapse)]="gfg">
        <form [formGroup]="form">
            <div class="row">
                <!--<div class="col-md-6">
                    <div class="form-group">
                        <label for="nome">Vendedor </label>
                        <input type="text" class="form-control" name="vendedor" id="vendedor"
                            formControlName="vendedor">

                    </div>
                </div>-->
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="nome">CPF ou CNPJ Gerente </label>
                        <input type="text" class="form-control" name="cpf_gerente" id="cpf_gerente"
                            [textMask]="{mask: cpfcnpjmask}" formControlName="cpf_gerente">

                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="nome">CPF ou CNPJ Vendedor </label>
                        <input type="text" class="form-control" name="cpf_vendedor" id="cpf_vendedor"
                            [textMask]="{mask: cpfcnpjmask}" formControlName="cpf_vendedor">

                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label for="nome">CPF ou CNPJ Cliente </label>
                        <input type="text" class="form-control" name="cpf_cnpj_cliente" id="cpf_cnpj_cliente"
                            [textMask]="{mask: cpfcnpjmask}" formControlName="cpf_cnpj_cliente">

                    </div>
                </div>
            </div>
            <div class="row">
                <!--<div class="col-md-6">
                    <div class="form-group">
                        <label for="nome">Gerente </label>
                        <input type="text" class="form-control" name="gerente" id="gerente" formControlName="gerente">

                    </div>
                </div>-->
                <!--
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="nome">CPF ou CNPJ Gerente </label>
                        <input type="text" class="form-control" name="cpf_gerente" id="cpf_gerente"  [textMask]="{mask: cpfcnpjmask}"
                            formControlName="cpf_gerente">

                    </div>
                </div>
            -->
            </div>

            <div class="row">
                <!--<div class="col-md-6">
                    <div class="form-group">
                        <label for="nome">Cliente </label>
                        <input type="text" class="form-control" name="cliente" id="cliente" formControlName="cliente">

                    </div>
                </div>-->
                <!--<div class="col-md-4">
                    <div class="form-group">
                        <label for="nome">CPF ou CNPJ Cliente </label>
                        <input type="text" class="form-control" name="cpf_cnpj_cliente" id="cpf_cnpj_cliente"  [textMask]="{mask: cpfcnpjmask}"
                            formControlName="cpf_cnpj_cliente">

                    </div>
                </div>-->
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="nome">NSAP Canal </label>
                        <input type="text" class="form-control" name="nsap" id="nsap" formControlName="nsap">

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="nome">CNPJ Canal </label>
                        <input type="text" class="form-control" [textMask]="{mask: cpfcnpjmask}" name="cnpj_canal"
                            id="cnpj_canal" formControlName="cnpj_canal">

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Tipo Nota</label>
                        <select class="form-control" name="tipoNota" id="tipoNota" formControlName="tipoNota">
                            <option value="serie">Serie</option>
                            <option value="data">Data</option>
                        </select>

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="nome">Chave de acesso </label>
                        <input type="text" class="form-control" name="chaveAcesso" id="chaveAcesso"
                            formControlName="chaveAcesso">

                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="nome">Numero NF </label>
                        <input type="text" class="form-control" name="nfNumero" id="nfNumero"
                            formControlName="nfNumero">

                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="nome">Data Início Emissão </label>
                        <input type="text" [textMask]="{mask: maskData}" class="form-control" placeholder="dd/mm/yyyy"
                            name="dataInicioEmissao" id="dataInicioEmissao" formControlName="dataInicioEmissao">

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="nome">Data Fim Emissão </label>
                        <input type="text" [textMask]="{mask: maskData}" class="form-control" placeholder="dd/mm/yyyy"
                            name="dataFimEmissao" id="dataFimEmissao" formControlName="dataFimEmissao">

                    </div>
                </div>
            </div>








            <button class="btn btn-warning " shape="round" (click)='limpar()'>
                Limpar Filtros
            </button>

            <button class="btn btn-primary ml-3" shape="round" (click)='pesquisar()'>
                Pesquisar
            </button>



        </form>
    </div>

</div>

<div class="row">
    <div class="col-md-12">

        <div class="col-md-3 float-right">
            <form [formGroup]="formSelect">

                <div class="form-group">
                    <label>Quantidade Registros Exibidos</label>
                    <select class="form-control" name="qtdeRegistros" id="qtdeRegistros" formControlName="qtdeRegistros"
                        (change)="trocaRegistros($event)">
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>

                    </select>

                </div>
            </form>
        </div>
    </div>

</div>

<div class="card card-primary">
    <div class="card-header">
        <h3 class="card-title">Resultados</h3>

        <div class="card-tools">
            <!-- Buttons, labels, and many other things can be placed here! -->
            <!-- Here is a label for example -->

        </div>
        <!-- /.card-tools -->
    </div>
    <!-- /.card-header -->
    <div class="card-body" #collapse="ngbCollapse" [(ngbCollapse)]="mostraRelacao">
        <button class="btn btn-success float-right " shape="round" (click)='exportexcel()' *ngIf="showTable">
            <i class="fa fa-file-excel"></i>
        </button>
        <div *ngIf="!showTable">
            Nota não contém produtos Syngenta
        </div>



        <div class="">
            <table class="table table-responsive table-bordered table-hover" *ngIf="showTable">
                <thead>
                    <tr>
                        <th>canal_syngenta_cod</th>
                        <th>nf_numero</th>
                        <th>nf_chave_cod</th>
                        <th>nf_data</th>
                        <th>nf_valor</th>
                        <th>nf_volume</th>
                        <th>nf_cfop</th>
                        <th>cpf/cnpj cliente</th>
                        <th>cpf/cnpj consultor</th>
                        <th>campanha_descricao</th>
                        <th>campanha_status</th>
                        <th>faturamento_id</th>
                        <th>produto_identificador_de_retirada</th>
                        <th>registro_invalidado_por</th>
                        <th>nf_cancelada_flg</th>

                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let registro of tempRelacao | paginate: { itemsPerPage: totalRegistrosExibidos, currentPage: p, totalItems:totalRegistros } ">

                        <td>{{registro.canal_syngenta_cod}}</td>
                        <td>{{registro.nf_numero}}</td>
                        <td>{{registro.nf_chave_cod}}</td>
                        <td>{{registro.nf_dt}}</td>
                        <td>{{registro.produto_valor}}</td>
                        <td>{{registro.produto_volume}}</td>
                        <td>{{registro.produto_cfop}}</td>

                        <td>{{registro.nf_cliente_crf_para_syngenta}}</td>
                        <td>{{registro.produto_consultor_crf_para_syngenta}}</td>
                        <td>{{registro.campanha_descricao}}</td>
                        <td>{{registro.campanha_status}}</td>

                        <td>{{registro.faturamento_id}}</td>

                        <td>{{registro.produto_identificador_de_retirada}}</td>
                        <td>{{registro.registro_invalidado_por}}</td>
                        <td>{{registro.nf_cancelada_flg}}</td>

                    </tr>

                </tbody>
            </table>
        </div>
        <div class="h-100 d-flex align-items-center justify-content-center" *ngIf="showTable">
            <pagination-controls (pageChange)="pageChanged($event)" previousLabel="Voltar"
                nextLabel="Proximo"></pagination-controls>
        </div>
    </div>


    <!--
    <div class="card-footer clearfix">
        <ul class="pagination pagination-sm m-0 float-right" *ngFor="let paginas of totalPaginas ">
            {{paginas}}
            <li class="page-item"><a class="page-link" href="#">«</a></li>
            <li class="page-item"><a class="page-link" href="#">1</a></li>
            <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item"><a class="page-link" href="#">»</a></li>
        </ul>
    </div>
-->

</div>
<!--
<p>
    <br />
    <button type="button" class="btn btn-success" (click)="collapse.toggle()" [attr.aria-expanded]="!gfg"
        aria-controls="collapseExample">
        Click to collapse
    </button>
</p>

<div #collapse="ngbCollapse" [(ngbCollapse)]="gfg">
    <div class="card">
        <div class="card-body">
            Angular10 ng bootstrap
        </div>
    </div>
</div>
-->



<table class="table table-bordered" id="excel-table" style="display: none;">
    <thead>
        <tr>
            <th>canal_syngenta_cod</th>
            <th>nf_numero</th>
            <th>nf_chave_cod</th>
            <th>nf_data</th>
            <th>nf_valor</th>
            <th>nf_volume</th>
            <th>nf_cfop</th>
            <th>nf_chave_cod</th>
            <th>nf_chave_cod</th>
            <th>nf_chave_cod</th>
            <th>cpf/cnpj cliente</th>
            <th>cpf/cnpj consultor</th>
            <th>campanha_descricao</th>
            <th>campanha_status</th>
            <th>faturamento_id</th>
            <th>produto_identificador_de_retirada</th>
            <th>registro_invalidado_por</th>
            <th>nf_cancelada_flg</th>

        </tr>
    </thead>
    <tbody>
        <tr
            *ngFor="let registro of tempRelacao | paginate: { itemsPerPage: totalRegistrosExibidos, currentPage: p, totalItems:totalRegistros } ">

            <td>{{registro.canal_syngenta_cod}}</td>
            <td>{{registro.nf_numero}}</td>
            <td>{{registro.nf_chave_cod}}</td>
            <td>{{registro.nf_dt}}</td>
            <td>{{registro.produto_valor}}</td>
            <td>{{registro.produto_volume}}</td>
            <td>{{registro.produto_cfop}}</td>

            <td>{{registro.nf_cliente_crf_para_syngenta}}</td>
            <td>{{registro.produto_consultor_crf_para_syngenta}}</td>
            <td>{{registro.campanha_descricao}}</td>
            <td>{{registro.campanha_status}}</td>

            <td>{{registro.faturamento_id}}</td>

            <td>{{registro.produto_identificador_de_retirada}}</td>
            <td>{{registro.registro_invalidado_por}}</td>
            <td>{{registro.nf_cancelada_flg}}</td>

        </tr>


    </tbody>
</table>

