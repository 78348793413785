import { Component, OnInit } from '@angular/core';
import { TelaService } from '@services/tela.service';
import { CriptografiaService } from '@services/criptografia.service';
import { AppService } from '@services/app.service';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
@Component({
  selector: 'app-add-tela',
  templateUrl: './add-tela.component.html',
  styleUrls: ['./add-tela.component.scss']
})
export class AddTelaComponent implements OnInit {
  private token: string;
  private idUsuario: string;
  public nm_tela;
  public icone;
  public url;
  public mostra_menu;

  public form: FormGroup;
  constructor(private api: TelaService,
    private apiCripto: CriptografiaService,
    private app: AppService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder) {

    this.app.getToken();
    this.app.getIdUsuario();
    this.token = this.app.token;
    this.idUsuario = this.app.idUsuario;
  }

  ngOnInit(): void {
    this.createForm();


  }

  createForm() {

    this.nm_tela = new FormControl('', [Validators.required]);
    this.icone = new FormControl('', [Validators.required]);
    this.url = new FormControl('', [Validators.required]);
    this.mostra_menu = new FormControl('', [Validators.required]);



    this.form = this.formBuilder.group({
      nm_tela: this.nm_tela,
      icone: this.icone,
      url: this.url,
      mostra_menu: this.mostra_menu
    });
    /*  this.form = this.formBuilder.group({
        nm_tela: ['', Validators.required],
        icone: ['', Validators.required],
        url: ['', Validators.required]     
      })*/
  }

  onSubmit() {
    
    const payload = {
      token: this.token,
      idUsuario: this.idUsuario
    }
    
    this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(e => {
        var tempKey = e.data;
        var keyPadrao = this.apiCripto.getKeyPadrao();
        var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));

        const payloadTela = {
          token: this.token,
          idUsuario: this.idUsuario,
          nm_tela: this.apiCripto.encryptUsingAES256Random(this.form.value.nm_tela.toString(), keyUser),
          icone: this.apiCripto.encryptUsingAES256Random(this.form.value.icone.toString(), keyUser),
          url: this.apiCripto.encryptUsingAES256Random(this.form.value.url.toString(), keyUser),
          mostra_menu: this.apiCripto.encryptUsingAES256Random(this.form.value.mostra_menu.toString(), keyUser),
        }
        this.api.addTela(payloadTela)
          .subscribe(res=>{
            if (res.status === 'Sucesso') {
              this.toastr.success(res.msg);
              this.router.navigate(['/lst-tela']);
            }
            else {
              this.toastr.error(res.msg);
            }
          })
      });
  }

  voltar() {
    this.router.navigate(['/lst-tela']);
  }

}
