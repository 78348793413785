import {
  Component,
  OnInit,
  OnDestroy,
  Renderer2,
  HostBinding
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { ActivatedRoute, Router } from '@angular/router';

import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-forcar-troca-senha',
  templateUrl: './forcar-troca-senha.component.html',
  styleUrls: ['./forcar-troca-senha.component.scss']
})
export class ForcarTrocaSenhaComponent implements OnInit, OnDestroy {

  @HostBinding('class') class = 'login-box';
  public loginForm: UntypedFormGroup;
  public isAuthLoading = false;
  public isGoogleLoading = false;
  public isFacebookLoading = false;
  public idUsuario;
  private key = CryptoJS.enc.Utf8.parse("46pzR1D9rnyyX6ifJhvAnIANYIeHsfWf");
  private iv = CryptoJS.enc.Utf8.parse("1tRLHFObk5orY9GD");

  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private router: Router,
    private _Activatedroute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.idUsuario = this._Activatedroute.snapshot.paramMap.get("idUsuario");
    this.renderer.addClass(
      document.querySelector('app-root'),
      'login-page'
    );
    this.loginForm = new UntypedFormGroup({
      senha_atual: new UntypedFormControl(null, Validators.required),
      senha1: new UntypedFormControl(null, Validators.required),
      senha2: new UntypedFormControl(null, Validators.required)
    });
  }

  encryptUsingAES256(text): any {
    var encrypted = CryptoJS.AES.encrypt(text, this.key,
      {
        mode: CryptoJS.mode.CBC,
        iv: this.iv
      });
    return encrypted.toString();
  }

  decryptUsingAES256(decString) {
    var decrypted = CryptoJS.AES.decrypt(decString, this.key, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }


  alterarSenha() {
    this.isAuthLoading = false;
    if (this.loginForm.valid) {
      this.isAuthLoading = true;
      console.log(this.loginForm.value)
      var senha_atual = CryptoJS.SHA256(this.loginForm.value.senha_atual).toString(CryptoJS.enc.Hex);
      var senha1 = CryptoJS.SHA256(this.loginForm.value.senha1).toString(CryptoJS.enc.Hex);
      var senha2 = CryptoJS.SHA256(this.loginForm.value.senha2).toString(CryptoJS.enc.Hex);
      if (senha_atual == senha1 || senha_atual == senha2) {
        this.toastr.error('A senha atual não pode ser igual a senha anterior');
      }
      else if (senha1 != senha2) {
        this.toastr.error('As senhas não coincide');
      }
      else {
        const payload = {
          senha_atual: this.encryptUsingAES256(senha_atual),
          senha1: this.encryptUsingAES256(senha1),
          senha2: this.encryptUsingAES256(senha2),
          idUsuario: this.idUsuario
        }
        this.appService.forcarSenha(payload)
          .subscribe(res => {

            if (res.status === 'Sucesso') {
              this.isAuthLoading =true;
              this.toastr.success('Dados alterados');
              this.router.navigateByUrl('/login');
              /*var data = res.data[0];
              var usuario = this.decryptUsingAES256(data.usuario);
              var forcar_troca = this.decryptUsingAES256(data.forcar_troca);
              console.log(forcar_troca);


              localStorage.setItem('token', data.token);
              localStorage.setItem('usuario', usuario);
              localStorage.setItem('perfil', data.perfil);
              localStorage.setItem('idUsuario', data.id_usuario);
              if (forcar_troca == 1) {

                this.router.navigateByUrl('/forcar-troca-senha/' + encodeURIComponent(data.id_usuario));
              }
              else {
                this.toastr.success('Login sucesso');
                this.router.navigate(['/consulta-nf']);
              }*/

            }
            else {
              this.toastr.error(res.msg);
              this.isAuthLoading =true;
            }

          });
       
      }
    } else {
      this.toastr.error('Form is not valid!');
    }
  }



  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'login-page'
    );
  }
}

