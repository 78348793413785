import { Component } from '@angular/core';

@Component({
  selector: 'app-add-perfil-tela',
  templateUrl: './add-perfil-tela.component.html',
  styleUrls: ['./add-perfil-tela.component.scss']
})
export class AddPerfilTelaComponent {

}
