import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { AppService } from '@services/app.service';
import { ConsultaService } from '@services/consulta.service';
import { CriptografiaService } from '@services/criptografia.service';
import * as CryptoJS from 'crypto-js';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-consulta-nf',
  templateUrl: './consulta-nf.component.html',
  styleUrls: ['./consulta-nf.component.scss']
})
export class ConsultaNfComponent implements OnInit {

  public vendedor;
  public cpf_vendedor;
  public gerente;
  public cpf_gerente;
  public cliente;
  public cpf_cnpj_cliente;
  public cnpj_canal;
  public dataInicioEmissao;
  public dataFimEmissao;
  public chaveAcesso;
  public qtdeRegistros;
  public tipoNota;
  public nfNumero;
  public nsap;

  public form: FormGroup;

  public formSelect: FormGroup;

  private token: string;
  private idUsuario: string;
  public gfg = false;
  public totalRegistros: number;
  public totalRegistrosExibidos: number = 100;
  public p: number = 0;
  public pagina: number = 0;
  public totalPaginas: number = 1;
  public relacao: any[] = [];
  public tempRelacao = [];
  public tempRelacaoExcel = [];
  public mostraRelacao = true;
  public fileName = 'dados_nf.xlsx';

  public showTable = false;

  public maskData = [/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private apiCripto: CriptografiaService,
    private formBuilder: FormBuilder,
    private app: AppService,
    private api: ConsultaService) {


    this.app.getToken();
    this.app.getIdUsuario();
    this.token = this.app.token;
    this.idUsuario = this.app.idUsuario;

  }
  ngOnInit(): void {
    this.createForm()
  }

  pageChanged(e) {
    this.p = e;
    this.pagina = this.p - 1;
    this.buscaPagina(this.p - 1);
  }

  limpar() {
    this.form.reset();
  }

  public cpfcnpjmask = function (rawValue) {
    var numbers = rawValue.match(/\d/g);
    var numberLength = 0;
    if (numbers) {
      numberLength = numbers.join('').length;
    }
    if (numberLength <= 11) {
      return [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
    } else {
      return [/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
    }
  }

  exportexcel(): void {


    if (this.validaCampos()) {



      this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
        .subscribe(e => {
          var tempKey = e.data;
          var keyPadrao = this.apiCripto.getKeyPadrao();
          var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));


          if (this.form.value.tipoNota == null) {
            this.form.value.tipoNota = ''
          }

          var tipoNotaTemp = this.apiCripto.encryptUsingAES256Random(this.form.value.tipoNota.toString(), keyUser);

          if (this.form.value.vendedor == null) {
            this.form.value.vendedor = ''
          }
          var vendedor = this.apiCripto.encryptUsingAES256Random(this.form.value.vendedor.toString(), keyUser);

          if (this.form.value.cpf_vendedor == null) {
            this.form.value.cpf_vendedor = ''
          }
          var cpf_vendedor = this.apiCripto.encryptUsingAES256Random(this.form.value.cpf_vendedor.toString().replace(/[^a-zA-Z0-9]/g, ''), keyUser);

          if (this.form.value.gerente == null) {
            this.form.value.gerente = ''
          }
          var gerente = this.apiCripto.encryptUsingAES256Random(this.form.value.gerente.toString(), keyUser);

          if (this.form.value.cpf_gerente == null) {
            this.form.value.cpf_gerente = ''
          }
          var cpf_gerente = this.apiCripto.encryptUsingAES256Random(this.form.value.cpf_gerente.toString().replace(/[^a-zA-Z0-9]/g, ''), keyUser);


          if (this.form.value.cliente == null) {
            this.form.value.cliente = ''
          }
          var cliente = this.apiCripto.encryptUsingAES256Random(this.form.value.cliente.toString(), keyUser)


          if (this.form.value.cpf_cnpj_cliente == null) {
            this.form.value.cpf_cnpj_cliente = ''
          }
          var cpf_cnpj_cliente = this.apiCripto.encryptUsingAES256Random(this.form.value.cpf_cnpj_cliente.toString().replace(/[^a-zA-Z0-9]/g, ''), keyUser);


          if (this.form.value.cnpj_canal == null) {
            this.form.value.cnpj_canal = ''
          }
          var cnpj_canal = this.apiCripto.encryptUsingAES256Random(this.form.value.cnpj_canal.toString().replace(/[^a-zA-Z0-9]/g, ''), keyUser);


          if (this.form.value.dataInicioEmissao == null) {
            this.form.value.dataInicioEmissao = ''
          }
          var dataInicioEmissao = this.apiCripto.encryptUsingAES256Random(this.formatarData(this.form.value.dataInicioEmissao.toString()), keyUser);

          if (this.form.value.dataFimEmissao == null) {
            this.form.value.dataFimEmissao = ''
          }
          var dataFimEmissao = this.apiCripto.encryptUsingAES256Random(this.formatarData(this.form.value.dataFimEmissao.toString()), keyUser);

          if (this.form.value.chaveAcesso == null) {
            this.form.value.chaveAcesso = ''
          }
          var chaveAcesso = this.apiCripto.encryptUsingAES256Random(this.form.value.chaveAcesso.toString(), keyUser);

          if (this.form.value.nfNumero == null) {
            this.form.value.nfNumero = ''
          }
          var nfNumero = this.apiCripto.encryptUsingAES256Random(this.form.value.nfNumero.toString(), keyUser);

          if (this.form.value.nsap == null) {
            this.form.value.nsap = ''
          }
          var nsap = this.apiCripto.encryptUsingAES256Random(this.form.value.nsap.toString(), keyUser);


          this.api.getBaseCompleta(encodeURIComponent(this.idUsuario),
            encodeURIComponent(this.token),
            encodeURIComponent(vendedor),
            encodeURIComponent(cpf_vendedor),
            encodeURIComponent(gerente),
            encodeURIComponent(cpf_gerente),
            encodeURIComponent(cliente),
            encodeURIComponent(cpf_cnpj_cliente),
            encodeURIComponent(cnpj_canal),
            encodeURIComponent(dataInicioEmissao),
            encodeURIComponent(dataFimEmissao),
            encodeURIComponent(chaveAcesso),
            encodeURIComponent(tipoNotaTemp),
            encodeURIComponent(nfNumero),
            encodeURIComponent(nsap))
            .subscribe(res => {
              if (res.status === 'Sucesso') {
                var temp = res.data;               
                temp.forEach(e => {
                  const temp1 = {
                    canal_syngenta_cod: this.apiCripto.decryptUsingAES256(e.canal_syngenta_cod, keyUser),
                    nf_numero: this.apiCripto.decryptUsingAES256(e.nf_numero, keyUser),
                    nf_chave_cod: this.apiCripto.decryptUsingAES256(e.nf_chave_cod, keyUser),
                    nf_cliente_crf_para_syngenta: this.apiCripto.decryptUsingAES256(e.nf_cliente_crf_para_syngenta, keyUser),
                    produto_consultor_crf_para_syngenta: this.apiCripto.decryptUsingAES256(e.produto_consultor_crf_para_syngenta, keyUser),
                    campanha_descricao: this.apiCripto.decryptUsingAES256(e.campanha_descricao, keyUser),
                    campanha_status: this.apiCripto.decryptUsingAES256(e.campanha_status, keyUser),
                    faturamento_id: this.apiCripto.decryptUsingAES256(e.faturamento_id, keyUser),
                    na_entrega_flg: this.apiCripto.decryptUsingAES256(e.na_entrega_flg, keyUser),
                    produto_identificador_de_retirada: this.apiCripto.decryptUsingAES256(e.produto_identificador_de_retirada.toString(), keyUser),
                    registro_invalidado_por: this.apiCripto.decryptUsingAES256(e.registro_invalidado_por, keyUser),
                    nf_cancelada_flg: this.apiCripto.decryptUsingAES256(e.nf_cancelada_flg, keyUser),
                    produto_valor: this.apiCripto.decryptUsingAES256(e.produto_valor, keyUser),
                    produto_volume: this.apiCripto.decryptUsingAES256(e.produto_volume, keyUser),
                    produto_cfop: this.apiCripto.decryptUsingAES256(e.produto_cfop, keyUser),
                    nf_dt: this.apiCripto.decryptUsingAES256(e.nf_dt, keyUser),

                  };

                  this.tempRelacaoExcel.push(temp1);
                });

                //var json = JSON.stringify(this.tempRelacaoExcel);

                /* table id is passed over here */
                //let element = document.getElementById('excel-table');
                //const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
                const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.tempRelacaoExcel);

                /* generate workbook and add the worksheet */
                const wb: XLSX.WorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

                /* save to file */
                XLSX.writeFile(wb, this.fileName);

              }
            })
        });
    }


  }


  createForm() {

    this.qtdeRegistros = new FormControl('');

    this.vendedor = new FormControl('');
    this.cpf_vendedor = new FormControl('');
    this.gerente = new FormControl('');
    this.cpf_gerente = new FormControl('');
    this.cliente = new FormControl('');
    this.cpf_cnpj_cliente = new FormControl('');
    this.cnpj_canal = new FormControl('');
    this.dataInicioEmissao = new FormControl('');
    this.dataFimEmissao = new FormControl('');
    this.chaveAcesso = new FormControl('');
    this.tipoNota = new FormControl('');
    this.nfNumero = new FormControl('');
    this.nsap = new FormControl('');


    this.form = this.formBuilder.group({
      vendedor: this.vendedor,
      cpf_vendedor: this.cpf_vendedor,
      gerente: this.gerente,
      cpf_gerente: this.cpf_gerente,
      cliente: this.cliente,
      cpf_cnpj_cliente: this.cpf_cnpj_cliente,
      cnpj_canal: this.cnpj_canal,
      dataInicioEmissao: this.dataInicioEmissao,
      dataFimEmissao: this.dataFimEmissao,
      chaveAcesso: this.chaveAcesso,
      tipoNota: this.tipoNota,
      nfNumero: this.nfNumero,
      nsap: this.nsap

    });


    this.formSelect = this.formBuilder.group({
      qtdeRegistros: this.qtdeRegistros
    });

    const temp = {
      qtdeRegistros: this.totalRegistrosExibidos
    }
    this.formSelect.setValue(temp);

  }


  trocaRegistros(e) {

    this.totalRegistrosExibidos = this.formSelect.value.qtdeRegistros;
    if (this.gfg) {
      this.buscaTotal();
    }

  }


  isValidCPF(cpf) {
    if (typeof cpf !== "string") return false
    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (
      !cpf ||
      cpf.length != 11 ||
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999"
    ) {
      return false
    }
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11)) resto = 0
    if (resto != parseInt(cpf.substring(9, 10))) return false
    soma = 0
    for (var i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11)) resto = 0
    if (resto != parseInt(cpf.substring(10, 11))) return false
    return true
  }

  validarCNPJ(cnpj) {

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
      return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999")
      return false;

    // Valida DVs
    var tamanho = cnpj.length - 2
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    var i;
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
      return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
      return false;

    return true;

  }

  validaCampos() {

    var cpf_cnpj_vendedor_bool = false;
    var cpf_gerente_bool = false;
    var cpf_cnpj_cliente_bool = false;
    var cnpj_canal_bool = false;

    if (this.form.value.cpf_vendedor != null && this.form.value.cpf_vendedor != '') {
      var cpf_cnpj_vendedor = this.form.value.cpf_vendedor.toString().replace(/[^a-zA-Z0-9]/g, '');

      //vendedor
      if (cpf_cnpj_vendedor.length > 0) {
        if (!this.isValidCPF(cpf_cnpj_vendedor)) {
          if (this.validarCNPJ(cpf_cnpj_vendedor)) {
            cpf_cnpj_vendedor_bool = true;
          }
        }
        else {
          cpf_cnpj_vendedor_bool = true;
        }

      }
      else {
        cpf_cnpj_vendedor_bool = true;
      }

    }
    else {
      cpf_cnpj_vendedor_bool = true;
    }

    if (this.form.value.cpf_gerente != null && this.form.value.cpf_gerente != '') {
      var cpf_gerente = this.form.value.cpf_gerente.toString().replace(/[^a-zA-Z0-9]/g, '');
      //gerente
      if (cpf_gerente.length > 0) {
        if (!this.isValidCPF(cpf_gerente)) {
          if (this.validarCNPJ(cpf_gerente)) {
            cpf_gerente_bool = true;
          }
        }
        else {
          cpf_gerente_bool = true;
        }

      }
      else {
        cpf_gerente_bool = true;
      }

    }
    else {
      cpf_gerente_bool = true;
    }

    if (this.form.value.cpf_cnpj_cliente != null && this.form.value.cpf_cnpj_cliente != '') {
      var cpf_cnpj_cliente = this.form.value.cpf_cnpj_cliente.toString().replace(/[^a-zA-Z0-9]/g, '');

      //cliente
      if (cpf_cnpj_cliente.length > 0) {
        if (!this.isValidCPF(cpf_cnpj_cliente)) {
          if (this.validarCNPJ(cpf_cnpj_cliente)) {
            cpf_cnpj_cliente_bool = true;
          }
        }
        else {
          cpf_cnpj_cliente_bool = true;
        }

      }
      else {
        cpf_cnpj_cliente_bool = true;
      }


    }

    else {
      cpf_cnpj_cliente_bool = true;
    }

    if (this.form.value.cnpj_canal != null && this.form.value.cnpj_canal != '') {
      var cnpj_canal = this.form.value.cnpj_canal.toString().replace(/[^a-zA-Z0-9]/g, '');

      if (cnpj_canal.length > 0) {
        if (this.validarCNPJ(cnpj_canal)) {
          cnpj_canal_bool = true;
        }
      }
      else {
        cnpj_canal_bool = true;
      }


    }
    else {
      cnpj_canal_bool = true;
    }


    if (!cpf_cnpj_vendedor_bool) {
      this.toastr.error("CPF/CNPJ vendedor inválido");
      return false;
    }
    if (!cpf_gerente_bool) {
      this.toastr.error("CPF/CNPJ gerente inválido");
      return false;
    }

    if (!cpf_cnpj_cliente_bool) {
      this.toastr.error("CPF/CNPJ cliente inválido");
      return false;
    }

    if (!cnpj_canal_bool) {
      this.toastr.error("CNPJ canal inválido");
      return false;
    }
    return true;
  }


  buscaPagina(pag: number) {


    this.mostraRelacao = false;
    this.tempRelacao = [];
    if (this.validaCampos()) {



      this.gfg = true;
      this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
        .subscribe(e => {
          var tempKey = e.data;
          var keyPadrao = this.apiCripto.getKeyPadrao();
          var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));
          var pagina = this.apiCripto.encryptUsingAES256Random(pag.toString(), keyUser);
          var limite = this.apiCripto.encryptUsingAES256Random(this.totalRegistrosExibidos.toString(), keyUser)


          if (this.form.value.tipoNota == null) {
            this.form.value.tipoNota = ''
          }

          var tipoNotaTemp = this.apiCripto.encryptUsingAES256Random(this.form.value.tipoNota.toString(), keyUser);

          if (this.form.value.vendedor == null) {
            this.form.value.vendedor = ''
          }
          var vendedor = this.apiCripto.encryptUsingAES256Random(this.form.value.vendedor.toString(), keyUser);

          if (this.form.value.cpf_vendedor == null) {
            this.form.value.cpf_vendedor = ''
          }
          var cpf_vendedor = this.apiCripto.encryptUsingAES256Random(this.form.value.cpf_vendedor.toString().replace(/[^a-zA-Z0-9]/g, ''), keyUser);

          if (this.form.value.gerente == null) {
            this.form.value.gerente = ''
          }
          var gerente = this.apiCripto.encryptUsingAES256Random(this.form.value.gerente.toString(), keyUser);

          if (this.form.value.cpf_gerente == null) {
            this.form.value.cpf_gerente = ''
          }
          var cpf_gerente = this.apiCripto.encryptUsingAES256Random(this.form.value.cpf_gerente.toString().replace(/[^a-zA-Z0-9]/g, ''), keyUser);


          if (this.form.value.cliente == null) {
            this.form.value.cliente = ''
          }
          var cliente = this.apiCripto.encryptUsingAES256Random(this.form.value.cliente.toString(), keyUser)


          if (this.form.value.cpf_cnpj_cliente == null) {
            this.form.value.cpf_cnpj_cliente = ''
          }
          var cpf_cnpj_cliente = this.apiCripto.encryptUsingAES256Random(this.form.value.cpf_cnpj_cliente.toString().replace(/[^a-zA-Z0-9]/g, ''), keyUser);


          if (this.form.value.cnpj_canal == null) {
            this.form.value.cnpj_canal = ''
          }
          var cnpj_canal = this.apiCripto.encryptUsingAES256Random(this.form.value.cnpj_canal.toString().replace(/[^a-zA-Z0-9]/g, ''), keyUser);


          if (this.form.value.dataInicioEmissao == null) {
            this.form.value.dataInicioEmissao = ''
          }
          var dataInicioEmissao = this.apiCripto.encryptUsingAES256Random(this.formatarData(this.form.value.dataInicioEmissao.toString()), keyUser);

          if (this.form.value.dataFimEmissao == null) {
            this.form.value.dataFimEmissao = ''
          }
          var dataFimEmissao = this.apiCripto.encryptUsingAES256Random(this.formatarData(this.form.value.dataFimEmissao.toString()), keyUser);

          if (this.form.value.chaveAcesso == null) {
            this.form.value.chaveAcesso = ''
          }
          var chaveAcesso = this.apiCripto.encryptUsingAES256Random(this.form.value.chaveAcesso.toString(), keyUser);

          if (this.form.value.nfNumero == null) {
            this.form.value.nfNumero = ''
          }
          var nfNumero = this.apiCripto.encryptUsingAES256Random(this.form.value.nfNumero.toString(), keyUser);

          if (this.form.value.nsap == null) {
            this.form.value.nsap = ''
          }
          var nsap = this.apiCripto.encryptUsingAES256Random(this.form.value.nsap.toString(), keyUser);


          this.api.getConsulta(encodeURIComponent(this.idUsuario),
            encodeURIComponent(this.token),
            encodeURIComponent(pagina),
            encodeURIComponent(limite),
            encodeURIComponent(vendedor),
            encodeURIComponent(cpf_vendedor),
            encodeURIComponent(gerente),
            encodeURIComponent(cpf_gerente),
            encodeURIComponent(cliente),
            encodeURIComponent(cpf_cnpj_cliente),
            encodeURIComponent(cnpj_canal),
            encodeURIComponent(dataInicioEmissao),
            encodeURIComponent(dataFimEmissao),
            encodeURIComponent(chaveAcesso),
            encodeURIComponent(tipoNotaTemp),
            encodeURIComponent(nfNumero),
            encodeURIComponent(nsap),
          )
            .subscribe(res => {
              if (res.status === 'Sucesso') {
                var temp = res.data;
                this.tempRelacao = [];

                temp.forEach(e => {


                  var na_entrega_flg = this.apiCripto.decryptUsingAES256(e.na_entrega_flg, keyUser);

                  if (na_entrega_flg == 0) {
                    var produto_fabricante = this.apiCripto.decryptUsingAES256(e.produto_fabricante, keyUser);
                    var produto_identificador_de_retirada = this.apiCripto.decryptUsingAES256(e.produto_identificador_de_retirada.toString(), keyUser)

                    if (produto_fabricante != 'SYNGENTA') {
                      this.showTable = false;
                    }
                    else if (produto_identificador_de_retirada != "Faturamento") {
                      this.showTable = true;
                      const temp1 = {
                        canal_syngenta_cod: this.apiCripto.decryptUsingAES256(e.canal_syngenta_cod, keyUser),
                        nf_numero: this.apiCripto.decryptUsingAES256(e.nf_numero, keyUser),
                        nf_chave_cod: this.apiCripto.decryptUsingAES256(e.nf_chave_cod, keyUser),
                        nf_cliente_crf_para_syngenta: this.apiCripto.decryptUsingAES256(e.nf_cliente_crf_para_syngenta, keyUser),
                        produto_consultor_crf_para_syngenta: this.apiCripto.decryptUsingAES256(e.produto_consultor_crf_para_syngenta, keyUser),
                        campanha_descricao: this.apiCripto.decryptUsingAES256(e.campanha_descricao, keyUser),
                        campanha_status: this.apiCripto.decryptUsingAES256(e.campanha_status, keyUser),
                        faturamento_id: this.apiCripto.decryptUsingAES256(e.faturamento_id, keyUser),
                        na_entrega_flg: this.apiCripto.decryptUsingAES256(e.na_entrega_flg, keyUser),
                        produto_identificador_de_retirada: this.apiCripto.decryptUsingAES256(e.produto_identificador_de_retirada.toString(), keyUser),
                        registro_invalidado_por: this.apiCripto.decryptUsingAES256(e.registro_invalidado_por, keyUser),
                        nf_cancelada_flg: this.apiCripto.decryptUsingAES256(e.nf_cancelada_flg, keyUser),
                        produto_valor: this.apiCripto.decryptUsingAES256(e.produto_valor, keyUser),
                        produto_volume: this.apiCripto.decryptUsingAES256(e.produto_volume, keyUser),
                        produto_cfop: this.apiCripto.decryptUsingAES256(e.produto_cfop, keyUser),
                        nf_dt: this.apiCripto.decryptUsingAES256(e.nf_dt, keyUser),

                      };
                      this.tempRelacao.push(temp1);
                    }

                  }
                  else {
                    this.showTable = true;
                    const temp1 = {
                      canal_syngenta_cod: this.apiCripto.decryptUsingAES256(e.canal_syngenta_cod, keyUser),
                      nf_numero: this.apiCripto.decryptUsingAES256(e.nf_numero, keyUser),
                      nf_chave_cod: this.apiCripto.decryptUsingAES256(e.nf_chave_cod, keyUser),
                      nf_cliente_crf_para_syngenta: this.apiCripto.decryptUsingAES256(e.nf_cliente_crf_para_syngenta, keyUser),
                      produto_consultor_crf_para_syngenta: this.apiCripto.decryptUsingAES256(e.produto_consultor_crf_para_syngenta, keyUser),
                      campanha_descricao: this.apiCripto.decryptUsingAES256(e.campanha_descricao, keyUser),
                      campanha_status: this.apiCripto.decryptUsingAES256(e.campanha_status, keyUser),
                      faturamento_id: this.apiCripto.decryptUsingAES256(e.faturamento_id, keyUser),
                      na_entrega_flg: this.apiCripto.decryptUsingAES256(e.na_entrega_flg, keyUser),
                      produto_identificador_de_retirada: this.apiCripto.decryptUsingAES256(e.produto_identificador_de_retirada.toString(), keyUser),
                      registro_invalidado_por: this.apiCripto.decryptUsingAES256(e.registro_invalidado_por, keyUser),
                      nf_cancelada_flg: this.apiCripto.decryptUsingAES256(e.nf_cancelada_flg, keyUser),
                      produto_valor: this.apiCripto.decryptUsingAES256(e.produto_valor, keyUser),
                      produto_volume: this.apiCripto.decryptUsingAES256(e.produto_volume, keyUser),
                      produto_cfop: this.apiCripto.decryptUsingAES256(e.produto_cfop, keyUser),
                      nf_dt: this.apiCripto.decryptUsingAES256(e.nf_dt, keyUser),

                    };
                    this.tempRelacao.push(temp1);
                  }



                });

              }
            })
        });
    }
  }

  formatarData(tempData) {
    if (tempData.length > 0) {
      const dataRecebidaDiaMesAno = tempData;
      const [dia, mes, ano] = dataRecebidaDiaMesAno.split('/');
      const data = ano + "-" + mes + "-" + dia;
      return data;
    }
    else {
      return null;
    }
  }



  buscaTotal() {

    if (this.validaCampos()) {

      if (this.form.value.chaveAcesso != '') {
        if (this.form.value.tipoNota == '') {
          this.toastr.error("Necessário informar o tipo da nota");
          return;
        }
        if (this.form.value.tipoNota == 'serie') {
          if (this.form.value.chaveAcesso.length < 31) {
            this.toastr.error("Chave da nota precisa ser no mínimo 31 caracteres");
            return;
          }
        }
        if (this.form.value.tipoNota == 'data') {
          if (this.form.value.chaveAcesso.length < 36) {
            this.toastr.error("Chave da nota precisa ser no mínimo 36 caracteres");
            return;
          }
        }
      }

      if ((this.form.value.vendedor != '' || this.form.value.cpf_vendedor != '' || this.form.value.cpf_vendedor != null)
        || (this.form.value.gerente != '' || this.form.value.cpf_gerente != '' || this.form.value.cpf_gerente != null)
        || (this.form.value.cliente != '' || this.form.value.cpf_cnpj_cliente != '' || this.form.value.cpf_cnpj_cliente != null)) {


        if (this.form.value.dataFimEmissao == '' || this.form.value.dataInicioEmissao == '' || this.form.value.dataFimEmissao == null || this.form.value.dataInicioEmissao == null) {
          this.toastr.error("Necessário informar o intervalo de data da NF");
          return;
        }

      }


      if ((this.form.value.vendedor == '' || this.form.value.vendedor == null) &&
        (this.form.value.cpf_vendedor == '' || this.form.value.cpf_vendedor == null) &&
        (this.form.value.gerente == '' || this.form.value.gerente == null) &&
        (this.form.value.cpf_gerente == '' || this.form.value.cpf_gerente == null) &&
        (this.form.value.cliente == '' || this.form.value.cliente == null) &&
        (this.form.value.cpf_cnpj_cliente == '' || this.form.value.cpf_cnpj_cliente == null) &&
        (this.form.value.cnpj_canal == '' || this.form.value.cnpj_canal == null) &&
        (this.form.value.dataInicioEmissao == '' || this.form.value.dataInicioEmissao == null) &&
        (this.form.value.dataFimEmissao == '' || this.form.value.dataFimEmissao == null) &&
        (this.form.value.chaveAcesso == '' || this.form.value.chaveAcesso == null)
      ) {
        this.toastr.error("Informe pelo menos 1 filtro");
      }
      else {
        this.gfg = true;
        this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
          .subscribe(e => {

            if (e.status == 'Sucesso') {

              var tempKey = e.data;
              var keyPadrao = this.apiCripto.getKeyPadrao();
              var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));


              if (this.form.value.tipoNota == null) {
                this.form.value.tipoNota = ''
              }

              var tipoNotaTemp = this.apiCripto.encryptUsingAES256Random(this.form.value.tipoNota.toString(), keyUser);

              if (this.form.value.vendedor == null) {
                this.form.value.vendedor = ''
              }
              var vendedor = this.apiCripto.encryptUsingAES256Random(this.form.value.vendedor.toString(), keyUser);

              if (this.form.value.cpf_vendedor == null) {
                this.form.value.cpf_vendedor = ''
              }
              var cpf_vendedor = this.apiCripto.encryptUsingAES256Random(this.form.value.cpf_vendedor.toString().replace(/[^a-zA-Z0-9]/g, ''), keyUser);

              if (this.form.value.gerente == null) {
                this.form.value.gerente = ''
              }
              var gerente = this.apiCripto.encryptUsingAES256Random(this.form.value.gerente.toString(), keyUser);

              if (this.form.value.cpf_gerente == null) {
                this.form.value.cpf_gerente = ''
              }
              var cpf_gerente = this.apiCripto.encryptUsingAES256Random(this.form.value.cpf_gerente.toString().replace(/[^a-zA-Z0-9]/g, ''), keyUser);


              if (this.form.value.cliente == null) {
                this.form.value.cliente = ''
              }
              var cliente = this.apiCripto.encryptUsingAES256Random(this.form.value.cliente.toString(), keyUser)


              if (this.form.value.cpf_cnpj_cliente == null) {
                this.form.value.cpf_cnpj_cliente = ''
              }
              var cpf_cnpj_cliente = this.apiCripto.encryptUsingAES256Random(this.form.value.cpf_cnpj_cliente.toString().replace(/[^a-zA-Z0-9]/g, ''), keyUser);


              if (this.form.value.cnpj_canal == null) {
                this.form.value.cnpj_canal = ''
              }
              var cnpj_canal = this.apiCripto.encryptUsingAES256Random(this.form.value.cnpj_canal.toString().replace(/[^a-zA-Z0-9]/g, ''), keyUser);


              if (this.form.value.dataInicioEmissao == null) {
                this.form.value.dataInicioEmissao = ''
              }
              var dataInicioEmissao = this.apiCripto.encryptUsingAES256Random(this.formatarData(this.form.value.dataInicioEmissao.toString()), keyUser);

              if (this.form.value.dataFimEmissao == null) {
                this.form.value.dataFimEmissao = ''
              }
              var dataFimEmissao = this.apiCripto.encryptUsingAES256Random(this.formatarData(this.form.value.dataFimEmissao.toString()), keyUser);

              if (this.form.value.chaveAcesso == null) {
                this.form.value.chaveAcesso = ''
              }
              var chaveAcesso = this.apiCripto.encryptUsingAES256Random(this.form.value.chaveAcesso.toString(), keyUser);


              if (this.form.value.nfNumero == null) {
                this.form.value.nfNumero = ''
              }

              var nfNumero = this.apiCripto.encryptUsingAES256Random(this.form.value.nfNumero.toString(), keyUser);

              if (this.form.value.nsap == null) {
                this.form.value.nsap = ''
              }
              var nsap = this.apiCripto.encryptUsingAES256Random(this.form.value.nsap.toString(), keyUser);
    


              this.api.getTotalConsulta(encodeURIComponent(this.idUsuario),
                encodeURIComponent(this.token),
                encodeURIComponent(vendedor),
                encodeURIComponent(cpf_vendedor),
                encodeURIComponent(gerente),
                encodeURIComponent(cpf_gerente),
                encodeURIComponent(cliente),
                encodeURIComponent(cpf_cnpj_cliente),
                encodeURIComponent(cnpj_canal),
                encodeURIComponent(dataInicioEmissao),
                encodeURIComponent(dataFimEmissao),
                encodeURIComponent(chaveAcesso),
                encodeURIComponent(tipoNotaTemp),
                encodeURIComponent(nfNumero),
                encodeURIComponent(nsap)
                )
                .subscribe(res => {
                  if (res.status === 'Sucesso') {


                    this.totalRegistros = this.apiCripto.decryptUsingAES256(res.data, keyUser);
                    this.totalPaginas = Math.ceil(this.totalRegistros / this.totalRegistrosExibidos);
                    this.buscaPagina(0);

                  }
                })

            }
            else {
              this.toastr.error(e.msg);
              window.location.reload();
            }
          });
      }
    }
  }

  pesquisar() {
    this.mostraRelacao = true;

    //this.buscaPagina(0);
    this.buscaTotal();



  }



}
