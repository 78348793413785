import { Injectable } from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router, private appService: AppService, private toastr: ToastrService) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
     //   console.log(next);
       
        return this.getProfile(state);
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.canActivate(next, state);
    }

    getProfile(state: RouterStateSnapshot) {
        
        this.appService.getToken();
        this.appService.getIdUsuario();
        const token = this.appService.token;
        const idUsuario = this.appService.idUsuario;

        const payload = {
            token: token,
            idUsuario: idUsuario,
            url: state.url
        }

        this.appService.validaSessao(payload)
            .subscribe(res => {

                if (res.status === 'Erro') {
                    this.toastr.error(res.msg);
                    this.appService.logout();
                    return false;
                }
            });

        if (this.appService.user) {
            return true;
        }


        try {
            this.appService.getProfile();
            return true;
        } catch (error) {
            return false;
        }

    }
}
