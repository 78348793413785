import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TelaService } from '@services/tela.service';
import { CriptografiaService } from '@services/criptografia.service';
import { AppService } from '@services/app.service';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
@Component({
  selector: 'app-edt-tela',
  templateUrl: './edt-tela.component.html',
  styleUrls: ['./edt-tela.component.scss']
})
export class EdtTelaComponent implements OnInit {

  public idTela: string;
  private token: string;
  private idUsuario: string;

  public tela;
  public nm_tela;
  public icone;
  public url;
  public id_tela;
  public mostra_menu;

  public form: FormGroup;

  constructor(private api: TelaService,
    private apiCripto: CriptografiaService,
    private app: AppService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private _Activatedroute: ActivatedRoute,) {

  }

  ngOnInit(): void {
    this.createForm();
    this.idTela = this._Activatedroute.snapshot.paramMap.get("idTela");
    this.app.getToken();
    this.app.getIdUsuario();
    this.token = this.app.token;
    this.idUsuario = this.app.idUsuario;
    this.buscarTela(this.idTela);

  }

  createForm() {

    this.nm_tela = new FormControl('', [Validators.required]);
    this.icone = new FormControl('', [Validators.required]);
    this.url = new FormControl('', [Validators.required]);
    this.id_tela = new FormControl('');
    this.mostra_menu = new FormControl('', [Validators.required]);


    this.form = this.formBuilder.group({
      id_tela: this.id_tela,
      nm_tela: this.nm_tela,
      icone: this.icone,
      url: this.url,
      mostra_menu: this.mostra_menu
    });
    /*  this.form = this.formBuilder.group({
        nm_tela: ['', Validators.required],
        icone: ['', Validators.required],
        url: ['', Validators.required]     
      })*/
  }

  buscarTela(tela: string) {
    this.api.getTela(encodeURIComponent(this.idTela), encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(res => {
        if (res.status === 'Sucesso') {
          this.tela = res.data[0];
          const payload = {
            token: this.token,
            idUsuario: this.idUsuario
          }
          this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
            .subscribe(e => {
              var tempKey = e.data;
              var keyPadrao = this.apiCripto.getKeyPadrao();
              var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));
              const temp1 = {
                nm_tela: this.apiCripto.decryptUsingAES256(this.tela.nm_tela, keyUser),
                icone: this.apiCripto.decryptUsingAES256(this.tela.icone, keyUser),
                url: this.apiCripto.decryptUsingAES256(this.tela.url, keyUser),
                id_tela: this.apiCripto.decryptUsingAES256(this.tela.id_tela, keyUser),
                mostra_menu: this.apiCripto.decryptUsingAES256(this.tela.mostra_menu, keyUser),
              };
              this.tela = temp1;
              this.form.setValue(this.tela);
            });
        }
        else {
          this.toastr.error(res.msg);
        }
      })
  }


  onSubmit() {
    
    const payload = {
      token: this.token,
      idUsuario: this.idUsuario
    }
    console.log(this.form.value.nm_tela.toString())
    this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(e => {
        var tempKey = e.data;
        var keyPadrao = this.apiCripto.getKeyPadrao();
        var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));

        const payloadTela = {
          token: this.token,
          idUsuario: this.idUsuario,
          id_tela: this.apiCripto.encryptUsingAES256Random(this.form.value.id_tela.toString(), keyUser),
          nm_tela: this.apiCripto.encryptUsingAES256Random(this.form.value.nm_tela.toString(), keyUser),
          icone: this.apiCripto.encryptUsingAES256Random(this.form.value.icone.toString(), keyUser),
          url: this.apiCripto.encryptUsingAES256Random(this.form.value.url.toString(), keyUser),
          mostra_menu: this.apiCripto.encryptUsingAES256Random(this.form.value.mostra_menu.toString(), keyUser)
        }
        this.api.updateTela(payloadTela)
          .subscribe(res => {
            if (res.status === 'Sucesso') {
              this.toastr.success(res.msg);
              this.router.navigate(['/lst-tela']);
            }
            else {
              this.toastr.error(res.msg);
            }
          })
      });
  }

  voltar() {
    this.router.navigate(['/lst-tela']);
  }
}
