<div class="card card-primary">
    <div class="card-header">
        <h3 class="card-title">Lista Tela</h3>
        <button class="btn btn-success float-right " shape="round" (click)='addTela()'>
            Adicionar
        </button>
        <div class="card-tools">
            <!-- Buttons, labels, and many other things can be placed here! -->
            <!-- Here is a label for example -->

        </div>
        <!-- /.card-tools -->
    </div>
    <!-- /.card-header -->
    <div class="card-body">

        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Id Tela</th>
                    <th>Tela</th>
                    <th>Icone</th>
                    <th>URL</th>
                    <th>Ações</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let registro of tempRelacao | paginate: { itemsPerPage: totalRegistrosExibidos, currentPage: p, totalItems:totalRegistros } ">

                    <td>{{registro.id_tela}}</td>
                    <td>{{registro.nm_tela}}</td>
                    <td>{{registro.icone}}</td>
                    <td>{{registro.url}}</td>
                    <td>
                        <button class="btn btn-warning" (click)="editar(registro)"> Editar</button>
                        <button class="btn btn-danger ml-3" (click)="deletar(registro)"> Apagar</button>

                    </td>
                </tr>

            </tbody>
        </table>
    </div>
    <div class="h-100 d-flex align-items-center justify-content-center">
        <pagination-controls (pageChange)="pageChanged($event)" previousLabel="Voltar"
            nextLabel="Proximo"></pagination-controls>
    </div>

    <!--
    <div class="card-footer clearfix">
        <ul class="pagination pagination-sm m-0 float-right" *ngFor="let paginas of totalPaginas ">
            {{paginas}}
            <li class="page-item"><a class="page-link" href="#">«</a></li>
            <li class="page-item"><a class="page-link" href="#">1</a></li>
            <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item"><a class="page-link" href="#">»</a></li>
        </ul>
    </div>
-->

</div>