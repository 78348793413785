import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { AppService } from '@services/app.service';
@Injectable({
  providedIn: 'root'
})
export class CriptografiaService {

  private keyPadrao = CryptoJS.enc.Utf8.parse("46pzR1D9rnyyX6ifJhvAnIANYIeHsfWf");
  private ivPadrao = CryptoJS.enc.Utf8.parse("1tRLHFObk5orY9GD");
  private keyUser;
  private ivUser;
  private idUsuario: string;
  private token: string;

  constructor(private http: HttpClient,
    private apiLogin: AppService) {




  }

  apiUrl = environment.baseUrl;
  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:4200'
      }
    )
  };


  generateIVUser(text: string) {
    var tamString = text.length;
    var first = text.substring(0, 8);
    var second = text.substring(tamString - 8, tamString);
    this.ivUser = CryptoJS.enc.Utf8.parse(first + second);

  }
  getTextoCorreto(text: string) {
    var tamString = text.length;
    return text.substring(8, tamString - 8);
  }

  getChaveUser() {
    this.apiLogin.getIdUsuario();
    this.apiLogin.getToken();
    this.idUsuario = this.apiLogin.idUsuario;
    this.token = this.apiLogin.token;
    const payload = {
      idUsuario: this.idUsuario,
      token: this.token
    }
    this.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token)).subscribe(res => {
      //console.log(res.data)
      var tempKey = res.data;
      this.generateIVUser(tempKey);
      tempKey = this.getTextoCorreto(tempKey);
      this.keyUser = CryptoJS.enc.Utf8.parse(this.decryptUsingAES256(tempKey, this.keyPadrao));
      //this.keyUser = CryptoJS.enc.Utf8.parse(this.decryptUsingAES256(res.data));
      return this.keyUser;

    });

  }

  buscaChaveUser(id_usuario: string, token: string) {
    const url = `${this.apiUrl}/GetChaveUsuario?idUsuario=${id_usuario}&token=${token}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(``)),
      catchError(this.handleError<any>(``))
    );
  }

  getIVUser() {
    return this.ivUser;
  }
  getIVPadrao() {
    return this.ivPadrao;
  }

  getKeyUser() {
    return this.keyUser;
  }
  getKeyPadrao() {
    return this.keyPadrao;
  }


  decryptUsingAES256(decString, key) {

    this.generateIVUser(decString);
    decString = this.getTextoCorreto(decString);

    var decrypted = CryptoJS.AES.decrypt(decString, key, {
      keySize: 128 / 8,
      iv: this.ivUser,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  generateRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  encryptUsingAES256Random(text, key) {
    var temp = this.generateRandomString(16);
    var tamString = temp.length;
    var first = temp.substring(0, 8);
    var second = temp.substring(tamString - 8, tamString);
    var iv = CryptoJS.enc.Utf8.parse(temp);
    var cypher = this.encryptUsingAES256(text, key, iv);

    first = first.concat(cypher, second);

    return first;

  }


  encryptUsingAES256(text, key, iv): any {
    var encrypted = CryptoJS.AES.encrypt(text, key,
      {
        mode: CryptoJS.mode.CBC,
        iv: iv
      });
    return encrypted.toString();
  }


  encryptUsingAES256User(text): any {
    var encrypted = CryptoJS.AES.encrypt(text, this.keyPadrao,
      {
        mode: CryptoJS.mode.CBC,
        iv: this.ivPadrao
      });
    return encrypted.toString();
  }

  decryptUsingAES256User(decString) {

    this.generateIVUser(decString);
    decString = this.getTextoCorreto(decString)
    //console.log(this.keyUser)
    //console.log(this.ivUser)
    var decrypted = CryptoJS.AES.decrypt(decString, this.keyUser, {
      keySize: 128 / 8,
      iv: this.ivUser,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      //console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(error as T);
    };
  }
}
