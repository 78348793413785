import { Component, OnInit } from '@angular/core';
import { PerfilService } from '@services/perfil.service'; 
import { CriptografiaService } from '@services/criptografia.service';
import { AppService } from '@services/app.service';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-add-perfil',
  templateUrl: './add-perfil.component.html',
  styleUrls: ['./add-perfil.component.scss']
})
export class AddPerfilComponent implements OnInit {

  private token: string;
  private idUsuario: string;
  public nm_perfil;


  public form: FormGroup;

  constructor(private api: PerfilService,
    private apiCripto: CriptografiaService,
    private app: AppService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder) {

    this.app.getToken();
    this.app.getIdUsuario();
    this.token = this.app.token;
    this.idUsuario = this.app.idUsuario;
  }
  ngOnInit(): void {
    this.createForm();
  }

  createForm() {

    this.nm_perfil = new FormControl('', [Validators.required]);  
    this.form = this.formBuilder.group({
      nm_perfil: this.nm_perfil,    
    });
    /*  this.form = this.formBuilder.group({
        nm_tela: ['', Validators.required],
        icone: ['', Validators.required],
        url: ['', Validators.required]     
      })*/
  }

  onSubmit() {
    
    const payload = {
      token: this.token,
      idUsuario: this.idUsuario
    }
    
    this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(e => {
        var tempKey = e.data;
        var keyPadrao = this.apiCripto.getKeyPadrao();
        var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));

        const payloadTela = {
          token: this.token,
          idUsuario: this.idUsuario,
          nm_perfil: this.apiCripto.encryptUsingAES256Random(this.form.value.nm_perfil.toString(), keyUser),
         
        }
        this.api.addPerfil(payloadTela)
          .subscribe(res=>{
            if (res.status === 'Sucesso') {
              this.toastr.success(res.msg);
              this.router.navigate(['/lst-perfil']);
            }
            else {
              this.toastr.error(res.msg);
            }
          })
      });
  }

  voltar() {
    this.router.navigate(['/lst-perfil']);
  }

}
