import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsultaService {

  constructor(private http: HttpClient) { }


  apiUrl = environment.baseUrl;
  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:4200'
      }
    )
  };

  getBaseCompleta(id_usuario: string, token: string, vendedor: string,
    cpf_vendedor: string, gerente: string, cpf_gerente: string, cliente: string, cpf_cnpj_cliente: string,
    cnpj_canal: string, dataInicioEmissao:string, dataFimEmissao: string, chaveAcesso: string, tipoNota:string, nfnumero:string, nsap:string) {

   
    const url = `${this.apiUrl}/consultaNFBase?idUsuario=${id_usuario}&token=${token}&vendedor=${vendedor}&cpf_vendedor=${cpf_vendedor}&gerente=${gerente}&cpf_gerente=${cpf_gerente}&cliente=${cliente}&cpf_cnpj_cliente=${cpf_cnpj_cliente}&cnpj_canal=${cnpj_canal}&dataInicioEmissao=${dataInicioEmissao}&dataFimEmissao=${dataFimEmissao}&chaveAcesso=${chaveAcesso}&tipoNota=${tipoNota}&nfnumero=${nfnumero}&nsap=${nsap}`;
    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap(_ => console.log("1")),
        catchError(this.handleError("getTodos", []))
      );
  }

  getConsulta(id_usuario: string, token: string, pagina:string,  limite: string, vendedor: string,
    cpf_vendedor: string, gerente: string, cpf_gerente: string, cliente: string, cpf_cnpj_cliente: string,
    cnpj_canal: string, dataInicioEmissao:string, dataFimEmissao: string, chaveAcesso: string, tipoNota:string, nfnumero:string, nsap:string ) {

   
    const url = `${this.apiUrl}/consultaNF?idUsuario=${id_usuario}&token=${token}&vendedor=${vendedor}&cpf_vendedor=${cpf_vendedor}&gerente=${gerente}&cpf_gerente=${cpf_gerente}&cliente=${cliente}&cpf_cnpj_cliente=${cpf_cnpj_cliente}&cnpj_canal=${cnpj_canal}&dataInicioEmissao=${dataInicioEmissao}&dataFimEmissao=${dataFimEmissao}&chaveAcesso=${chaveAcesso}&tipoNota=${tipoNota}&nfnumero=${nfnumero}&nsap=${nsap}&pagina=${pagina}&limite=${limite}`;
    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap(_ => console.log("1")),
        catchError(this.handleError("getTodos", []))
      );
  }

  getTotalConsulta(id_usuario: string, token: string, vendedor: string,
    cpf_vendedor: string, gerente: string, cpf_gerente: string, cliente: string, cpf_cnpj_cliente: string,
    cnpj_canal: string, dataInicioEmissao:string, dataFimEmissao: string, chaveAcesso: string, tipoNota:string, nfnumero:string, nsap:string ) {

   
    const url = `${this.apiUrl}/consultaNFTotal?idUsuario=${id_usuario}&token=${token}&vendedor=${vendedor}&cpf_vendedor=${cpf_vendedor}&gerente=${gerente}&cpf_gerente=${cpf_gerente}&cliente=${cliente}&cpf_cnpj_cliente=${cpf_cnpj_cliente}&cnpj_canal=${cnpj_canal}&dataInicioEmissao=${dataInicioEmissao}&dataFimEmissao=${dataFimEmissao}&chaveAcesso=${chaveAcesso}&tipoNota=${tipoNota}&nfnumero=${nfnumero}&nsap=${nsap}`;
    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap(_ => console.log("1")),
        catchError(this.handleError("getTodos", []))
      );
  }

  
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      //console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(error as T);
    };
  }
}
