<div class="float-right d-none d-sm-block">
    <b>
        <a href="https://bridsolucoes.com.br/" target="_blank" style="margin: 0">
            <span>BRID Soluções </span>
        </a>
    </b>
    <b> Version </b> {{ appVersion }}
   <span>{{ currentYear }}</span>
</div>

