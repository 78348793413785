<div class="card card-success">
    <div class="card-header">
        <h3 class="card-title">Adicionar Perfil</h3>

        <div class="card-tools">
         

        </div>
        
    </div>
    
    <div class="card-body">

        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="form-group">
                <label for="nome">Perfil <span style="color:red;">*</span></label>
                <input type="text" class="form-control" name="nm_perfil" id="nm_perfil" formControlName="nm_perfil">
                <div style="color:red;" [hidden]="nm_perfil.valid || nm_perfil.untouched || !nm_perfil.hasError('required')">                                  
                    Nome do perfil é obrigatório
                </div>
            </div>

            
            <div style="color:red;">                                  
                * Campos obrigatórios
             </div>




            <button class="btn btn-warning " shape="round" (click)='voltar()'>
                Voltar
            </button>
            <input type="submit" [disabled]="!form.valid" class="btn btn-primary ml-3" value="Salvar">
            

        </form>

    </div>




</div>