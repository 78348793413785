import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  constructor(private http: HttpClient,) { }


  apiUrl = environment.baseUrl;
  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:4200'
      }
    )
  };

  addPerfil(payload) {
    return this.http.post<any>(`${this.apiUrl}/perfil`, payload, this.httpOptions).pipe(
      tap(_ => console.log("Cadastrado")),
      catchError(this.handleError("", []))
    );
  }

  addPerfilTela(payload) {
    return this.http.post<any>(`${this.apiUrl}/perfilTela`, payload, this.httpOptions).pipe(
      tap(_ => console.log("Cadastrado")),
      catchError(this.handleError("", []))
    );
  }

  updatePerfil(payload): Observable<any> {
    const url = `${this.apiUrl}/perfil`;
    return this.http.put(url, payload, this.httpOptions).pipe(
      tap(_ => console.log(``)),
      catchError(this.handleError<any>("updateTodo"))
    );
  }

  getTotalPerfil(id_usuario: string, token: string) {
    const url = `${this.apiUrl}/perfilTotal?idUsuario=${id_usuario}&token=${token}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(``)),
      catchError(this.handleError<any>(``))
    );
  }


  getPerfis(id_usuario: string, token: string, pagina: string, limite: string): Observable<any> {
    const url = `${this.apiUrl}/perfilRel?idUsuario=${id_usuario}&token=${token}&pagina=${pagina}&limite=${limite}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(``)),
      catchError(this.handleError<any>(``))
    );
  }

  getPerfil(id: string, id_usuario: string, token: string): Observable<any> {
    const url = `${this.apiUrl}/perfil?idUsuario=${id_usuario}&token=${token}&idPerfil=${id}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`fetched todo id=${id}`)),
      catchError(this.handleError<any>(`getTodo id=${id}`))
    );
  }

  
  getTodosPerfis(id_usuario: string, token: string): Observable<any> {
    const url = `${this.apiUrl}/perfilTodos?idUsuario=${id_usuario}&token=${token}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(``)),
      catchError(this.handleError<any>(``))
    );
  }

  getPerfilPermissao(id: string, id_usuario: string, token: string): Observable<any> {
    const url = `${this.apiUrl}/perfilPermissao?idUsuario=${id_usuario}&token=${token}&idPerfil=${id}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`fetched todo id=${id}`)),
      catchError(this.handleError<any>(`getTodo id=${id}`))
    );
  }






  deletePerfil(id_usuario: string, token: string, id: string) {
    const url = `${this.apiUrl}/perfil?idUsuario=${id_usuario}&token=${token}&idPerfil=${id}`;
    return this.http.delete<any>(url).pipe(
      tap(_ => console.log(`fetched todo id=${id}`)),
      catchError(this.handleError<any>(`getTodo id=${id}`))
    );
  }


  deletePerfilTela(id_usuario: string, token: string, id: string) {
    const url = `${this.apiUrl}/perfil_tela?idUsuario=${id_usuario}&token=${token}&idPerfilTela=${id}`;
    return this.http.delete<any>(url).pipe(
      tap(_ => console.log(`fetched todo id=${id}`)),
      catchError(this.handleError<any>(`getTodo id=${id}`))
    );
  }






  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      //console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(error as T);
    };
  }
}
