import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PerfilService } from '@services/perfil.service';
import { CriptografiaService } from '@services/criptografia.service';
import { AppService } from '@services/app.service';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { TelaService } from '@services/tela.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-lst-perfil-tela',
  templateUrl: './lst-perfil-tela.component.html',
  styleUrls: ['./lst-perfil-tela.component.scss']
})
export class LstPerfilTelaComponent implements OnInit {
  public idPerfil: string;
  private token: string;
  private idUsuario: string;
  public perfilTela;
  public perfil;
  public nm_perfil;

  public id_tela;

  public form: FormGroup;


  public totalRegistros: number;
  public totalRegistrosExibidos: number = 5;
  public p: number = 0;
  public pagina: number = 0;
  public totalPaginas: number = 1;
  public relacao: any[] = [];
  public tempRelacao = [];
  public telaRelacao = [];

  constructor(private api: PerfilService,
    private apiCripto: CriptografiaService,
    private app: AppService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private _Activatedroute: ActivatedRoute,
    private telaApi: TelaService) {

  }

  ngOnInit(): void {

    this.idPerfil = this._Activatedroute.snapshot.paramMap.get("idPerfil");
    this.app.getToken();
    this.app.getIdUsuario();
    this.token = this.app.token;
    this.idUsuario = this.app.idUsuario;
    this.buscarPerfil();
    this.buscarPerfilTela();
    this.createForm()
    this.buscarTela();

  }

  buscarTela() {
    this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(e => {
        var tempKey = e.data;
        var keyPadrao = this.apiCripto.getKeyPadrao();
        var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));


        this.telaApi.getTelaSel(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
          .subscribe(res => {
            if (res.status === 'Sucesso') {
              var temp = res.data;
              this.tempRelacao = [];
              temp.forEach(e => {
                const temp1 = {
                  nm_tela: this.apiCripto.decryptUsingAES256(e.nm_tela, keyUser),
                  id_tela: this.apiCripto.decryptUsingAES256(e.id_tela, keyUser),
                };
                this.telaRelacao.push(temp1);


              });
              console.log(this.telaRelacao);
              //console.log(this.relacao);
            }
            else {
              this.toastr.error(res.msg);
            }
          })
      });

  }


  createForm() {

    this.id_tela = new FormControl('', [Validators.required]);
    this.form = this.formBuilder.group({
      id_tela: this.id_tela,
    });
    /*  this.form = this.formBuilder.group({
        nm_tela: ['', Validators.required],
        icone: ['', Validators.required],
        url: ['', Validators.required]     
      })*/
  }

  onSubmit() {

    const find = this.tempRelacao.find(e => e.id_tela === this.form.value.id_tela);

    if (find === undefined) {
      console.log(this.idPerfil);
      this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
        .subscribe(e => {
          var tempKey = e.data;
          var keyPadrao = this.apiCripto.getKeyPadrao();
          var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));

          const payload = {
            token: this.token,
            idUsuario: this.idUsuario,
            id_perfil: this.idPerfil.toString(),
            id_tela: this.apiCripto.encryptUsingAES256Random(this.form.value.id_tela.toString(), keyUser),
          }
        
          this.api.addPerfilTela(payload)
            .subscribe(res => {
              if (res.status === 'Sucesso') {
                this.toastr.success(res.msg);
                this.form.reset();
                this.buscarPerfilTela();
                window.location.reload();
              }
              else {
                this.toastr.error(res.msg);
              }
            })
        });
   
    }
    else {
      this.toastr.error("Tela já está cadastrada");
    }
  }
  voltar() {
    this.router.navigate(['/lst-perfil']);
  }

  buscarPerfilTela() {
    this.api.getPerfilPermissao(encodeURIComponent(this.idPerfil), encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(res => {
        if (res.status === 'Sucesso') {
          var temp = res.data;
          this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
            .subscribe(e => {
              var tempKey = e.data;
              var keyPadrao = this.apiCripto.getKeyPadrao();
              var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));
              this.tempRelacao = [];
              temp.forEach(e => {
                const temp1 = {
                  nm_perfil: this.apiCripto.decryptUsingAES256(e.nm_perfil.toString(), keyUser),
                  id_perfil: this.apiCripto.decryptUsingAES256(e.id_perfil.toString(), keyUser),
                  nm_tela: this.apiCripto.decryptUsingAES256(e.nm_tela.toString(), keyUser),
                  id_tela: this.apiCripto.decryptUsingAES256(e.id_tela.toString(), keyUser),
                  id_perfil_tela: this.apiCripto.decryptUsingAES256(e.id_perfil_tela.toString(), keyUser),
                };
                this.tempRelacao.push(temp1);
                

              });
            
            });
        }
       
      });
  }

  buscarPerfil() {
    this.api.getPerfil(encodeURIComponent(this.idPerfil), encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(res => {
        if (res.status === 'Sucesso') {
          this.perfil = res.data[0];

          const payload = {
            token: this.token,
            idUsuario: this.idUsuario
          }
          this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
            .subscribe(e => {
              var tempKey = e.data;
              var keyPadrao = this.apiCripto.getKeyPadrao();
              var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));
              const temp1 = {
                nm_perfil: this.apiCripto.decryptUsingAES256(this.perfil.nm_perfil, keyUser),
                id_perfil: this.apiCripto.decryptUsingAES256(this.perfil.id_perfil, keyUser),
              };

              this.perfil = temp1;

            });
        }
        else {
          this.toastr.error(res.msg);
        }
      })
  }

  pageChanged(e: any) {
    this.p = e;
    this.pagina = this.p - 1;
    //this.buscarPerfils(this.p - 1);
  }

  deletar(registro: any) {

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Você tem certeza?',
      text: "Não será possível reverter a exclusão!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Não Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          token: this.token,
          idUsuario: this.idUsuario
        }
        this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
          .subscribe(e => {
            var tempKey = e.data;
            var keyPadrao = this.apiCripto.getKeyPadrao();
            var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));



            this.api.deletePerfilTela(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token), encodeURIComponent(this.apiCripto.encryptUsingAES256Random(registro.id_perfil_tela.toString(), keyUser)))
              .subscribe(res => {
                if (res.status == 'Sucesso') {
                 this.buscarPerfilTela();
                  this.toastr.success(res.msg);
                  window.location.reload();
                }
                else {
                  this.toastr.error(res.msg);
                }
              })
          });


      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.toastr.error("Cancelado");
      }
    })

  }

}
