import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '@services/usuario.service';
import { CriptografiaService } from '@services/criptografia.service';
import { AppService } from '@services/app.service';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-lst-usuario',
  templateUrl: './lst-usuario.component.html',
  styleUrls: ['./lst-usuario.component.scss']
})
export class LstUsuarioComponent implements OnInit {

  private token: string;
  private idUsuario: string;
  public totalRegistros: number;
  public totalRegistrosExibidos: number = 5;
  public p: number = 0;
  public pagina: number = 0;
  public totalPaginas: number = 1;
  public relacao: any[] = [];
  public tempRelacao = [];
  public form: FormGroup;
  public gfg = false;
  public usuario;
  public email;
  public perfil;
  public mostraRelacao = true;


  constructor(private api: UsuarioService,
    private apiCripto: CriptografiaService,
    private app: AppService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,) {

    this.app.getToken();
    this.app.getIdUsuario();
    this.token = this.app.token;
    this.idUsuario = this.app.idUsuario;
    this.createForm();
  }



  createForm() {



    this.usuario = new FormControl('');
    this.email = new FormControl('');
    this.perfil = new FormControl('');



    this.form = this.formBuilder.group({
      usuario: this.usuario,
      email: this.email,
      perfil: this.perfil,

    });




  }

  ngOnInit(): void {
    this.buscaTotalUsuario();
  }

  buscaTotalUsuario() {

    this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(e => {

        var tempKey = e.data;
        var keyPadrao = this.apiCripto.getKeyPadrao();
        var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));

        if (this.form.value.usuario == null) {
          this.form.value.usuario = ''
        }
        console.log(this.form.value.usuario)
        var tempUsuario = this.apiCripto.encryptUsingAES256Random(this.form.value.usuario.toString(), keyUser);
        console.log(tempUsuario)
        if (this.form.value.email == null) {
          this.form.value.email = ''
        }

        var tempEmail = this.apiCripto.encryptUsingAES256Random(this.form.value.email.toString(), keyUser);

        if (this.form.value.perfil == null) {
          this.form.value.perfil = ''
        }

        var tmpPerfil = this.apiCripto.encryptUsingAES256Random(this.form.value.perfil.toString(), keyUser);


        this.p = 0;
        this.pagina = 0;
        this.api.getTotalUsuario(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token), encodeURIComponent(tempUsuario), encodeURIComponent(tempEmail), encodeURIComponent(tmpPerfil))
          .subscribe(res => {
            if (res.status === 'Sucesso') {
              var temp = res.data;
              this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
                .subscribe(e => {
                  var tempKey = e.data;
                  var keyPadrao = this.apiCripto.getKeyPadrao();
                  var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));
                  this.totalRegistros = this.apiCripto.decryptUsingAES256(temp[0].total, keyUser);
                  this.totalPaginas = Math.ceil(this.totalRegistros / this.totalRegistrosExibidos);
                  this.buscarUsuarios(0);
                });
            }
          });

      });



  }

  limpar() {
    this.form.reset();
  }

  pesquisar() {
    this.buscaTotalUsuario();
  }

  buscarUsuarios(pag: number) {
    const payload = {
      token: this.token,
      idUsuario: this.idUsuario
    }
    this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(e => {
        var tempKey = e.data;
        var keyPadrao = this.apiCripto.getKeyPadrao();
        var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));
        var pagina = this.apiCripto.encryptUsingAES256Random(pag.toString(), keyUser);
        var limite = this.apiCripto.encryptUsingAES256Random(this.totalRegistrosExibidos.toString(), keyUser)

        if (this.form.value.usuario == null) {
          this.form.value.usuario = ''
        }
        console.log(this.form.value.usuario)
        var tempUsuario = this.apiCripto.encryptUsingAES256Random(this.form.value.usuario.toString(), keyUser);
        console.log(tempUsuario)
        if (this.form.value.email == null) {
          this.form.value.email = ''
        }

        var tempEmail = this.apiCripto.encryptUsingAES256Random(this.form.value.email.toString(), keyUser);

        if (this.form.value.perfil == null) {
          this.form.value.perfil = ''
        }

        var tempPerfil = this.apiCripto.encryptUsingAES256Random(this.form.value.perfil.toString(), keyUser);

        this.api.getUsuarios(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token), encodeURIComponent(pagina), encodeURIComponent(limite),
        encodeURIComponent(tempUsuario),encodeURIComponent(tempEmail),encodeURIComponent(tempPerfil))
          .subscribe(res => {
            if (res.status === 'Sucesso') {
              var temp = res.data;
              this.tempRelacao = [];
              temp.forEach(e => {
                const temp1 = {
                  id_usuario: this.apiCripto.decryptUsingAES256(e.id_usuario, keyUser),
                  nome: this.apiCripto.decryptUsingAES256(e.nome, keyUser),
                  email: this.apiCripto.decryptUsingAES256(e.email, keyUser),
                  nm_perfil: this.apiCripto.decryptUsingAES256(e.nm_perfil, keyUser),
                  chave: this.apiCripto.decryptUsingAES256(e.chave, keyUser),
                  id_situacao: this.apiCripto.decryptUsingAES256(e.id_situacao, keyUser),
                  id_perfil: this.apiCripto.decryptUsingAES256(e.id_perfil, keyUser),
                };
                this.tempRelacao.push(temp1);


              });
              //console.log(this.relacao);
            }
            else {
              this.toastr.error(res.msg);
            }
          })
      });
  }


  addPerfil() {
    this.router.navigate(['/add-usuario']);
  }

  editar(registro: any) {
    const payload = {
      token: this.token,
      idUsuario: this.idUsuario
    }
    this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(e => {
        var tempKey = e.data;
        var keyPadrao = this.apiCripto.getKeyPadrao();
        var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));
        var id_usuario = this.apiCripto.encryptUsingAES256Random(registro.id_usuario.toString(), keyUser);
        //console.log(encodeURIComponent(idTela))
        this.router.navigateByUrl('/edt-usuario/' + encodeURIComponent(id_usuario));
      });
  }
  deletar(registro: any) {

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Você tem certeza?',
      text: "Não será possível reverter a exclusão!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Não Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          token: this.token,
          idUsuario: this.idUsuario
        }
        this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
          .subscribe(e => {
            var tempKey = e.data;
            var keyPadrao = this.apiCripto.getKeyPadrao();
            var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));



            this.api.deleteUsuario(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token), encodeURIComponent(this.apiCripto.encryptUsingAES256Random(registro.id_usuario.toString(), keyUser)))
              .subscribe(res => {
                if (res.status == 'Sucesso') {
                  this.buscaTotalUsuario();
                  this.toastr.success(res.msg);
                }
                else {
                  this.toastr.error(res.msg);
                }
              })
          });


      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.toastr.error("Cancelado");
      }
    })

  }


  pageChanged(e: any) {
    this.p = e;
    this.pagina = this.p - 1;
    this.buscarUsuarios(this.p - 1);
  }
}
