import { Component } from '@angular/core';

@Component({
  selector: 'app-edt-perfil-tela',
  templateUrl: './edt-perfil-tela.component.html',
  styleUrls: ['./edt-perfil-tela.component.scss']
})
export class EdtPerfilTelaComponent {

}
