<div class="card card-success">
    <div class="card-header">
        <h3 class="card-title">Adicionar Tela</h3>

        <div class="card-tools">
            <!-- Buttons, labels, and many other things can be placed here! -->
            <!-- Here is a label for example -->

        </div>
        <!-- /.card-tools -->
    </div>
    <!-- /.card-header -->
    <div class="card-body">

        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="form-group">
                <label for="nome">Tela <span style="color:red;">*</span></label>
                <input type="text" class="form-control" name="nm_tela" id="nm_tela" formControlName="nm_tela">
                <div style="color:red;" [hidden]="nm_tela.valid || nm_tela.untouched || !nm_tela.hasError('required')">                                  
                    Nome da tela é obrigatória
                </div>
            </div>

            <div class="form-group">
                <label for="nome">Icone <span style="color:red;">*</span></label>
                <input type="text" class="form-control" name="icone" id="icone" formControlName="icone">
                <div style="color:red;" [hidden]="icone.valid || icone.untouched || !icone.hasError('required')">                                  
                  Icone é  um campo obrigatória
                </div>
            </div>

            <div class="form-group">
                <label for="nome">Url <span style="color:red;">*</span></label>
                <input type="text" class="form-control" name="url" id="url" formControlName="url">
                <div style="color:red; font:bold" [hidden]="url.valid || url.untouched || !url.hasError('required')">                                  
                   URL é obrigatório
                </div>
            </div>

            <div class="form-group">
                <label>Mostrar Tela no menu?</label>
                <select class="form-control" name="mostra_menu" id="mostra_menu" formControlName="mostra_menu">
                    <option  value="0">Não Mostrar</option>
                    <option  value="1">Mostrar</option>
                    
                </select>
                <div style="color:red;"
                    [hidden]="mostra_menu.valid || mostra_menu.untouched || !mostra_menu.hasError('required')">
                    Tela é obrigatório
                </div>
            </div>
            <div style="color:red;">                                  
                * Campos obrigatórios
             </div>





            <button class="btn btn-warning " shape="round" (click)='voltar()'>
                Voltar
            </button>
            <input type="submit" [disabled]="!form.valid" class="btn btn-primary ml-3" value="Salvar">
            

        </form>

    </div>




</div>