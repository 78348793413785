import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';

import {CommonModule, registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserComponent} from '@modules/main/header/user/user.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {MainMenuComponent} from './pages/main-menu/main-menu.component';
import {SubMenuComponent} from './pages/main-menu/sub-menu/sub-menu.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {ControlSidebarComponent} from './modules/main/control-sidebar/control-sidebar.component';
import {StoreModule} from '@ngrx/store';
import {authReducer} from './store/auth/reducer';
import {uiReducer} from './store/ui/reducer';
import {ProfabricComponentsModule} from '@profabric/angular-components';
import {SidebarSearchComponent} from './components/sidebar-search/sidebar-search.component';
import { LstUsuarioComponent } from './pages/usuario/lst-usuario/lst-usuario.component';
import { AddUsuarioComponent } from './pages/usuario/add-usuario/add-usuario.component';
import { EdtUsuarioComponent } from './pages/usuario/edt-usuario/edt-usuario.component';
import { EdtPerfilComponent } from './pages/perfil/edt-perfil/edt-perfil.component';
import { LstPerfilComponent } from './pages/perfil/lst-perfil/lst-perfil.component';
import { AddPerfilComponent } from './pages/perfil/add-perfil/add-perfil.component';
import { ConsultaNfComponent } from './pages/tela-consulta/consulta-nf/consulta-nf.component';
import { LstTelaComponent } from './pages/tela/lst-tela/lst-tela.component';
import { AddTelaComponent } from './pages/tela/add-tela/add-tela.component';
import { EdtTelaComponent } from './pages/tela/edt-tela/edt-tela.component';
import { LstPerfilTelaComponent } from './pages/perfil-tela/lst-perfil-tela/lst-perfil-tela.component';
import { AddPerfilTelaComponent } from './pages/perfil-tela/add-perfil-tela/add-perfil-tela.component';
import { EdtPerfilTelaComponent } from './pages/perfil-tela/edt-perfil-tela/edt-perfil-tela.component';
import { ForcarTrocaSenhaComponent } from './pages/forcar-troca-senha/forcar-troca-senha.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerComponent } from '@pages/spinner/spinner.component';
import { LoadingInterceptor } from '@services/loading.interceptor';
import { TextMaskModule } from 'angular2-text-mask';
registerLocaleData(localeEn, 'en-EN');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        RegisterComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        ControlSidebarComponent,
        SidebarSearchComponent,
        LstUsuarioComponent,
        AddUsuarioComponent,
        EdtUsuarioComponent,
        EdtPerfilComponent,
        LstPerfilComponent,
        AddPerfilComponent,
        ConsultaNfComponent,
        LstTelaComponent,
        AddTelaComponent,
        EdtTelaComponent,
        LstPerfilTelaComponent,
        AddPerfilTelaComponent,
        EdtPerfilTelaComponent,
        ForcarTrocaSenhaComponent,
        SpinnerComponent
        
    ],
    imports: [
        ProfabricComponentsModule,
        CommonModule,
        BrowserModule,
        StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        TextMaskModule,
        NgxPaginationModule,
      
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        NgbModule
    ],
    providers: [{
        provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
      }],
    bootstrap: [AppComponent]
})
export class AppModule {}
