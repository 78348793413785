import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {MainMenuComponent} from '@pages/main-menu/main-menu.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import { AddPerfilComponent } from '@pages/perfil/add-perfil/add-perfil.component';
import { LstPerfilComponent } from '@pages/perfil/lst-perfil/lst-perfil.component';
import { EdtPerfilComponent } from '@pages/perfil/edt-perfil/edt-perfil.component';
import { AddUsuarioComponent } from '@pages/usuario/add-usuario/add-usuario.component';
import { LstUsuarioComponent } from '@pages/usuario/lst-usuario/lst-usuario.component';
import { EdtUsuarioComponent } from '@pages/usuario/edt-usuario/edt-usuario.component';
import { ConsultaNfComponent } from '@pages/tela-consulta/consulta-nf/consulta-nf.component';
import { LstTelaComponent } from '@pages/tela/lst-tela/lst-tela.component';
import { EdtTelaComponent } from '@pages/tela/edt-tela/edt-tela.component';
import { AddTelaComponent } from '@pages/tela/add-tela/add-tela.component';
import { LstPerfilTelaComponent } from '@pages/perfil-tela/lst-perfil-tela/lst-perfil-tela.component';
import { ForcarTrocaSenhaComponent } from './pages/forcar-troca-senha/forcar-troca-senha.component';
const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'profile',
                component: ProfileComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'blank',
                component: BlankComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'sub-menu-1',
                component: SubMenuComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'sub-menu-2',
                component: BlankComponent,
                canActivate: [AuthGuard]
            },
            {
                path: '',
                component: ConsultaNfComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'lst-usuario',
                component: LstUsuarioComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'add-usuario',
                component: AddUsuarioComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'edt-usuario/:idUsuario',
                component: EdtUsuarioComponent,
                canActivate: [AuthGuard]
            },
        
            {
                path: 'lst-perfil',
                component: LstPerfilComponent,
                canActivate: [AuthGuard]
            },
        
            {
                path: 'add-perfil',
                component: AddPerfilComponent,
                canActivate: [AuthGuard]
            },
        
            {
                path: 'edt-perfil/:idPerfil',
                component: EdtPerfilComponent,
                canActivate: [AuthGuard]
            },
        
            {
                path: 'consulta-nf',
                component: ConsultaNfComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'lst-tela',
                component: LstTelaComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'add-tela',
                component: AddTelaComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'edt-tela/:idTela',
                component: EdtTelaComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'lst-perfil-tela/:idPerfil',
                component: LstPerfilTelaComponent,
                canActivate: [AuthGuard]
            },
            
        ]
    },
   
   
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent        
    },
    {
        path: 'recover-password/:token',
        component: RecoverPasswordComponent        
    },
    {
        path: 'forcar-troca-senha/:idUsuario',
        component: ForcarTrocaSenhaComponent
        
    },
    {path: '**', redirectTo: 'login'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
