<div class="card card-success">
    <div class="card-header">
        <h3 class="card-title">Adicionar Permissão</h3>



    </div>

    <div class="card-body">

        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="form-group">
                <label>Tela</label>
                <select class="form-control" name="id_tela" id="id_tela" formControlName="id_tela">
                    <option  *ngFor="let option of telaRelacao " value="{{option.id_tela}}">{{option.nm_tela}}</option>
                    
                </select>
                <div style="color:red;"
                    [hidden]="id_tela.valid || id_tela.untouched || !id_tela.hasError('required')">
                    Tela é obrigatório
                </div>
            </div>


            <div style="color:red;">
                * Campos obrigatórios
            </div>




            <button class="btn btn-warning " shape="round" (click)='voltar()'>
                Voltar
            </button>
            <input type="submit" [disabled]="!form.valid" class="btn btn-primary ml-3" value="Salvar">


        </form>

    </div>




</div>


<div class="card card-primary">
    <div class="card-header">
        <h3 class="card-title">Lista Permissões - {{perfil.nm_perfil}}</h3>

        <div class="card-tools">


        </div>

    </div>

    <div class="card-body">

        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Id Tela</th>
                    <th>Tela</th>
                    <th>Ações</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let registro of tempRelacao ">

                    <td>{{registro.id_tela}}</td>
                    <td>{{registro.nm_tela}}</td>
                    <td>

                        <button class="btn btn-danger ml-3" (click)="deletar(registro)"> Apagar</button>

                    </td>
                </tr>

            </tbody>
        </table>
    </div>
   



</div>