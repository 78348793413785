import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Gatekeeper } from 'gatekeeper-client-sdk';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, of, tap, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;
    public perfil: any = null;
    public token: any = null;
    public idUsuario: any = null;
    apiUrl = environment.baseUrl;
    httpOptions = {
        headers: new HttpHeaders(
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:4200'
            }
        )
    };
    constructor(private router: Router, private toastr: ToastrService, private http: HttpClient,) { }

    loginByAuth(payload) {
        try {

            return this.http.post<any>(`${this.apiUrl}/login`, payload, this.httpOptions).pipe(
                tap(_ => console.log("Cadastrado")),
                catchError(this.handleError("addCategoria", []))
            );

            //const token = await Gatekeeper.loginByAuth(email, password);
            //localStorage.setItem('token', token);
            //await this.getProfile();
            //this.router.navigate(['/']);
            //this.toastr.success('Login success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    forcarSenha(usuario): Observable<any> {
        const url = `${this.apiUrl}/forcarSenha`;
        return this.http.put(url, usuario, this.httpOptions).pipe(
          tap(_ => console.log(``)),
          catchError(this.handleError<any>("updateTodo"))
        );
      }

    async registerByAuth({ email, password }) {
        try {
            const token = await Gatekeeper.registerByAuth(email, password);
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async loginByGoogle() {
        try {
            const token = await Gatekeeper.loginByGoogle();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Login success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByGoogle() {
        try {
            const token = await Gatekeeper.registerByGoogle();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async loginByFacebook() {
        try {
            const token = await Gatekeeper.loginByFacebook();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Login success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByFacebook() {
        try {
            const token = await Gatekeeper.registerByFacebook();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async getProfile() {
        try {
            this.user = localStorage.getItem("usuario");
            if (this.user === null) {
                this.logout();
            }
        } catch (error) {
            this.logout();
            throw error;
        }
    }

    async getPerfil() {
        try {
            this.perfil = localStorage.getItem("perfil");
        } catch (error) {
            console.log(error)
            this.logout();
            throw error;
        }
    }

    async getIdUsuario() {
        try {
            this.idUsuario = localStorage.getItem("idUsuario");
        } catch (error) {
            this.logout();
            throw error;
        }
    }


    async getToken() {
        try {
            this.token = localStorage.getItem("token");
        } catch (error) {
            this.logout();
            throw error;
        }
    }


    validaSessao(payload) {
        return this.http.post<any>(`${this.apiUrl}/validaSessao`, payload, this.httpOptions).pipe(
            tap(_ => console.log("Validando Sessão")),
            catchError(this.handleError("Sessão", []))
        );
    }


    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('idUsuario');
        localStorage.removeItem('perfil');
        localStorage.removeItem('usuario');
        //localStorage.removeItem('gatekeeper_token');
        this.user = null;
        this.router.navigate(['/login']);
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.log(error)
            // TODO: send the error to remote logging infrastructure
            //console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(error as T);
        };
    }
}
