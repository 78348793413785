import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {


  constructor(private http: HttpClient,) { }

  apiUrl = environment.baseUrl;
  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:4200'
      }
    )
  };


  recuperarSenha(payload) {
    return this.http.post<any>(`${this.apiUrl}/recuperarSenha`, payload, this.httpOptions).pipe(
      tap(_ => console.log("")),
      catchError(this.handleError("addUser", []))
    );
  }

  alterarSenha(payload) {
    return this.http.post<any>(`${this.apiUrl}/alterarSenha`, payload, this.httpOptions).pipe(
      tap(_ => console.log("")),
      catchError(this.handleError("addUser", []))
    );
  }
  getTotalUsuario(id_usuario: string, token: string, usuario: string, email:string, perfil:string): Observable<any> {
    const url = `${this.apiUrl}/usuarioTotal?idUsuario=${id_usuario}&token=${token}&usuario=${usuario}&email=${email}&perfil=${perfil}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`fetched todo id=`)),
      catchError(this.handleError<any>(`getTodo id=`))
    );
  }
  getUsuarios(id_usuario: string, token: string, pagina: string, limite: string, usuario: string, email:string, perfil:string) {
    const url = `${this.apiUrl}/usuarioRel?idUsuario=${id_usuario}&token=${token}&pagina=${pagina}&limite=${limite}&usuario=${usuario}&email=${email}&perfil=${perfil}`;
    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap(_ => console.log("1")),
        catchError(this.handleError("getTodos", []))
      );
  }

  getUsuario(id_usuario: string, token: string, id:string) {
    const url = `${this.apiUrl}/usuario?idUsuario=${id_usuario}&token=${token}&id=${id}`;
    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap(_ => console.log("1")),
        catchError(this.handleError("getTodos", []))
      );
  }

  getSituacao(id_usuario: string, token: string) {
    const url = `${this.apiUrl}/situacao?idUsuario=${id_usuario}&token=${token}`;
    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap(_ => console.log("1")),
        catchError(this.handleError("getTodos", []))
      );
  }

  addUsuario(usuario) {
    return this.http.post<any>(`${this.apiUrl}/usuario`, usuario, this.httpOptions).pipe(
      tap(_ => console.log("Cadastrado")),
      catchError(this.handleError("addUser", []))
    );
  }
  updateUsuario(usuario): Observable<any> {
    const url = `${this.apiUrl}/usuario`;
    return this.http.put(url, usuario, this.httpOptions).pipe(
      tap(_ => console.log(``)),
      catchError(this.handleError<any>("updateTodo"))
    );
  }

  deleteUsuario(id_usuario: string, token: string,id): Observable<any> {
    const url = `${this.apiUrl}/usuario?idUsuario=${id_usuario}&token=${token}&id=${id}`;
    return this.http.delete<any>(url, this.httpOptions).pipe(
      tap(_ => console.log(`deleted todo id=${id}`)),
      catchError(this.handleError<any>("deletetodo"))
    );
  }





  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      //console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(error as T);
    };
  }
}
