import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PerfilService } from '@services/perfil.service';
import { CriptografiaService } from '@services/criptografia.service';
import { AppService } from '@services/app.service';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-edt-perfil',
  templateUrl: './edt-perfil.component.html',
  styleUrls: ['./edt-perfil.component.scss']
})
export class EdtPerfilComponent implements OnInit {

  public idPerfil: string;
  private token: string;
  private idUsuario: string;

  public perfil;
  public nm_perfil;

  public id_perfil;

  public form: FormGroup;

  constructor(private api: PerfilService,
    private apiCripto: CriptografiaService,
    private app: AppService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private _Activatedroute: ActivatedRoute,) {

  }

  ngOnInit(): void {
    this.createForm();
    this.idPerfil = this._Activatedroute.snapshot.paramMap.get("idPerfil");
    this.app.getToken();
    this.app.getIdUsuario();
    this.token = this.app.token;
    this.idUsuario = this.app.idUsuario;
    this.buscarPerfil();
  }


  createForm() {

    this.nm_perfil = new FormControl('', [Validators.required]);
    this.id_perfil = new FormControl('');

    this.form = this.formBuilder.group({
      id_perfil: this.id_perfil,
      nm_perfil: this.nm_perfil
    });
    /*  this.form = this.formBuilder.group({
        nm_tela: ['', Validators.required],
        icone: ['', Validators.required],
        url: ['', Validators.required]     
      })*/
  }

  buscarPerfil() {
    this.api.getPerfil(encodeURIComponent(this.idPerfil), encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(res => {
        if (res.status === 'Sucesso') {
          this.perfil = res.data[0];
          
          const payload = {
            token: this.token,
            idUsuario: this.idUsuario
          }
          this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
            .subscribe(e => {
              var tempKey = e.data;
              var keyPadrao = this.apiCripto.getKeyPadrao();
              var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));
              const temp1 = {
                nm_perfil: this.apiCripto.decryptUsingAES256(this.perfil.nm_perfil, keyUser),               
                id_perfil: this.apiCripto.decryptUsingAES256(this.perfil.id_perfil, keyUser),
              };
              
              this.perfil = temp1;
              this.form.setValue(this.perfil);
            });
        }
        else {
          this.toastr.error(res.msg);
        }
      })
  }

  onSubmit() {
    
    const payload = {
      token: this.token,
      idUsuario: this.idUsuario
    }
    
    this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(e => {
        var tempKey = e.data;
        var keyPadrao = this.apiCripto.getKeyPadrao();
        var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));

        const payloadTela = {
          token: this.token,
          idUsuario: this.idUsuario,
          id_perfil: this.apiCripto.encryptUsingAES256Random(this.form.value.id_perfil.toString(), keyUser),
          nm_perfil: this.apiCripto.encryptUsingAES256Random(this.form.value.nm_perfil.toString(), keyUser)         
        }
        this.api.updatePerfil(payloadTela)
          .subscribe(res => {
            if (res.status === 'Sucesso') {
              this.toastr.success(res.msg);
              this.router.navigate(['/lst-perfil']);
            }
            else {
              this.toastr.error(res.msg);
            }
          })
      });
  }

  voltar() {
    this.router.navigate(['/lst-perfil']);
  }
}
