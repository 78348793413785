<div class="card card-dark">
    <div class="card-header">
        <h3 class="card-title">Alterar Usuário</h3>

        <div class="card-tools">


        </div>

    </div>

    <div class="card-body">

        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="form-group">
                <label for="nome">Nome <span style="color:red;">*</span></label>
                <input type="text" class="form-control" name="nome" id="nome" formControlName="nome">
                <div style="color:red;" [hidden]="nome.valid || nome.untouched || !nome.hasError('required')">
                    Nome é obrigatório
                </div>
            </div>

            <div class="form-group">
                <label for="nome">E-mail <span style="color:red;">*</span></label>
                <input type="text" class="form-control" name="email" id="email" formControlName="email">
                <div style="color:red;" [hidden]="email.valid || email.untouched || !email.hasError('required')">
                    E-mail é obrigatório
                </div>
            </div>

            <div class="form-group">
                <label for="nome">Senha <span style="color:red;">*</span></label>
                <input type="password" class="form-control" name="senha" id="senha" formControlName="senha">
                <div style="color:red;" [hidden]="senha.valid || senha.untouched || !senha.hasError('required')">
                    Senha é obrigatório
                </div>
            </div>
            <div class="form-check">
                <input type="checkbox" class="form-check-input" id="forcar_troca"  formControlName="forcar_troca">
                <label class="form-check-label" for="forcar_troca">Forçar a troca de senha</label>
            </div>
           

            <div class="form-group">
                <label>Situação</label>
                <select class="form-control" name="id_situacao" id="id_situacao" formControlName="id_situacao">
                    <option  *ngFor="let option of situacaoRelacao " value="{{option.id_situacao}}">{{option.desc_situacao}}</option>

                </select>
                <div style="color:red;"
                    [hidden]="id_situacao.valid || id_situacao.untouched || !id_situacao.hasError('required')">
                    Situação é obrigatório
                </div>
            </div>

            <div class="form-group">
                <label>Perfil</label>
                <select class="form-control" name="id_perfil" id="id_perfil" formControlName="id_perfil">
                    <option  *ngFor="let option of perfilRelacao " value="{{option.id_perfil}}">{{option.nm_perfil}}</option>

                </select>
                <div style="color:red;"
                    [hidden]="id_perfil.valid || id_perfil.untouched || !id_perfil.hasError('required')">
                    Perfil é obrigatório
                </div>
            </div>



            <div style="color:red;">
                * Campos obrigatórios
            </div>




            <button class="btn btn-warning " shape="round" (click)='voltar()'>
                Voltar
            </button>
            <input type="submit" [disabled]="!form.valid" class="btn btn-primary ml-3" value="Salvar">


        </form>

    </div>




</div>