import { Component, OnInit } from '@angular/core';
import { PerfilService } from '@services/perfil.service';
import { CriptografiaService } from '@services/criptografia.service';
import { AppService } from '@services/app.service';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lst-perfil',
  templateUrl: './lst-perfil.component.html',
  styleUrls: ['./lst-perfil.component.scss']
})
export class LstPerfilComponent implements OnInit {
  private token: string;
  private idUsuario: string;
  public totalRegistros: number;
  public totalRegistrosExibidos: number = 5;
  public p: number = 0;
  public pagina: number = 0;
  public totalPaginas: number = 1;
  public relacao: any[] = [];
  public tempRelacao = [];


  constructor(private api: PerfilService,
    private apiCripto: CriptografiaService,
    private app: AppService,
    private toastr: ToastrService,
    private router: Router) {

    this.app.getToken();
    this.app.getIdUsuario();
    this.token = this.app.token;
    this.idUsuario = this.app.idUsuario;
  }
  ngOnInit(): void {
    this.buscaTotalPerfil();
  }

  buscaTotalPerfil() {
    const payload = {
      token: this.token,
      idUsuario: this.idUsuario
    }
    this.p = 0;
    this.pagina = 0;
    this.api.getTotalPerfil(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(res => {
        if (res.status === 'Sucesso') {
          var temp = res.data;
          this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
            .subscribe(e => {
              var tempKey = e.data;
              var keyPadrao = this.apiCripto.getKeyPadrao();
              var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));
              this.totalRegistros = this.apiCripto.decryptUsingAES256(temp[0].total, keyUser);
              this.totalPaginas = Math.ceil(this.totalRegistros / this.totalRegistrosExibidos);
              this.buscarPerfils(0);
            });
        }
      });
  }

  buscarPerfils(pag: number) {
    const payload = {
      token: this.token,
      idUsuario: this.idUsuario
    }
    this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(e => {
        var tempKey = e.data;
        var keyPadrao = this.apiCripto.getKeyPadrao();
        var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));
        var pagina = this.apiCripto.encryptUsingAES256Random(pag.toString(), keyUser);
        var limite = this.apiCripto.encryptUsingAES256Random(this.totalRegistrosExibidos.toString(), keyUser)

        this.api.getPerfis(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token), encodeURIComponent(pagina), encodeURIComponent(limite))
          .subscribe(res => {
            if (res.status === 'Sucesso') {
              var temp = res.data;
              this.tempRelacao = [];
              temp.forEach(e => {
                const temp1 = {
                  nm_perfil: this.apiCripto.decryptUsingAES256(e.nm_perfil, keyUser),
                  id_perfil: [this.apiCripto.decryptUsingAES256(e.id_perfil, keyUser)],
                };
                this.tempRelacao.push(temp1);


              });
              //console.log(this.relacao);
            }
            else {
              this.toastr.error(res.msg);
            }
          })
      });

  }

  addPerfil() {
    this.router.navigate(['/add-perfil']);
  }

  editar(registro: any) {
    const payload = {
      token: this.token,
      idUsuario: this.idUsuario
    }
    this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(e => {
        var tempKey = e.data;
        var keyPadrao = this.apiCripto.getKeyPadrao();
        var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));
        var idPerfil = this.apiCripto.encryptUsingAES256Random(registro.id_perfil.toString(), keyUser);
        //console.log(encodeURIComponent(idTela))
        this.router.navigateByUrl('/edt-perfil/' + encodeURIComponent(idPerfil));
      });
  }
  deletar(registro: any) {

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Você tem certeza?',
      text: "Não será possível reverter a exclusão!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Não Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          token: this.token,
          idUsuario: this.idUsuario
        }
        this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
          .subscribe(e => {
            var tempKey = e.data;
            var keyPadrao = this.apiCripto.getKeyPadrao();
            var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));



            this.api.deletePerfil(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token), encodeURIComponent(this.apiCripto.encryptUsingAES256Random(registro.id_perfil.toString(), keyUser)))
              .subscribe(res => {
                if (res.status == 'Sucesso') {
                  this.buscaTotalPerfil();
                  this.toastr.success(res.msg);
                }
                else {
                  this.toastr.error(res.msg);
                }
              })
          });


      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.toastr.error("Cancelado");
      }
    })

  }

  permissao(registro:any){
    const payload = {
      token: this.token,
      idUsuario: this.idUsuario
    }
    this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(e => {
        var tempKey = e.data;
        var keyPadrao = this.apiCripto.getKeyPadrao();
        var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));
        var idPerfil = this.apiCripto.encryptUsingAES256Random(registro.id_perfil.toString(), keyUser);
        //console.log(encodeURIComponent(idTela))
        this.router.navigateByUrl('/lst-perfil-tela/' + encodeURIComponent(idPerfil));
      });
  }
  pageChanged(e: any) {
    this.p = e;
    this.pagina = this.p - 1;
    this.buscarPerfils(this.p - 1);
  }
}
