import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '@services/usuario.service';
import { PerfilService } from '@services/perfil.service';
import { CriptografiaService } from '@services/criptografia.service';
import { AppService } from '@services/app.service';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-edt-usuario',
  templateUrl: './edt-usuario.component.html',
  styleUrls: ['./edt-usuario.component.scss']
})
export class EdtUsuarioComponent implements OnInit {
  private token: string;
  private idUsuario: string;
  public id_usuario;
  public nome;
  public email;
  public senha;
  public id_situacao;
  public id_perfil;
  public forcar_troca;
  public form: FormGroup;
  public perfilRelacao = [];
  public situacaoRelacao = [];
  public id: string;
  public usuario: any;
  constructor(private api: UsuarioService,
    private apiPerfil: PerfilService,
    private apiCripto: CriptografiaService,
    private app: AppService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private _Activatedroute: ActivatedRoute,) {

    this.app.getToken();
    this.app.getIdUsuario();
    this.token = this.app.token;
    this.idUsuario = this.app.idUsuario;
    this.id = this._Activatedroute.snapshot.paramMap.get("idUsuario");
    console.log(this.id)
  }
  ngOnInit(): void {
    this.createForm();
    this.buscarUsuario()

  }

  buscaSituacoes() {
    this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(e => {
        var tempKey = e.data;
        var keyPadrao = this.apiCripto.getKeyPadrao();
        var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));

        this.api.getSituacao(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
          .subscribe(res => {
            if (res.status === 'Sucesso') {
              var temp = res.data;
              this.situacaoRelacao = [];
              temp.forEach(e => {
                const temp1 = {
                  desc_situacao: this.apiCripto.decryptUsingAES256(e.desc_situacao, keyUser),
                  id_situacao: this.apiCripto.decryptUsingAES256(e.id_situacao, keyUser),
                };
                this.situacaoRelacao.push(temp1);


              });
              //console.log(this.relacao);
            }
            else {
              this.toastr.error(res.msg);
            }
          })
      });
  }


  buscarUsuario() {
    this.api.getUsuario(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token), encodeURIComponent(this.id))
      .subscribe(res => {
        if (res.status === 'Sucesso') {
          this.usuario = res.data[0];

          const payload = {
            token: this.token,
            idUsuario: this.idUsuario
          }
          this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
            .subscribe(e => {
              var tempKey = e.data;
              var keyPadrao = this.apiCripto.getKeyPadrao();
              var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));
              const temp1 = {
                id_usuario: this.apiCripto.decryptUsingAES256(this.usuario.id_usuario, keyUser),
                nome: this.apiCripto.decryptUsingAES256(this.usuario.nome, keyUser),
                email: this.apiCripto.decryptUsingAES256(this.usuario.email, keyUser),
                id_situacao: this.apiCripto.decryptUsingAES256(this.usuario.id_situacao, keyUser),
                id_perfil: this.apiCripto.decryptUsingAES256(this.usuario.id_perfil, keyUser),
                senha: '',
                forcar_troca:false

              };

              this.usuario = temp1;
              this.form.setValue(this.usuario);
            });
        }
        else {
          this.toastr.error(res.msg);
        }
      })
  }

  buscarPerfils() {

    this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(e => {
        var tempKey = e.data;
        var keyPadrao = this.apiCripto.getKeyPadrao();
        var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));

        this.apiPerfil.getTodosPerfis(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
          .subscribe(res => {
            if (res.status === 'Sucesso') {
              var temp = res.data;
              this.perfilRelacao = [];
              temp.forEach(e => {
                const temp1 = {
                  nm_perfil: this.apiCripto.decryptUsingAES256(e.nm_perfil, keyUser),
                  id_perfil: this.apiCripto.decryptUsingAES256(e.id_perfil, keyUser),
                };
                this.perfilRelacao.push(temp1);


              });
              this.buscaSituacoes()
              //console.log(this.relacao);
            }
            else {
              this.toastr.error(res.msg);
            }
          })
      });

  }

  createForm() {

    this.id_usuario = new FormControl('');
    this.nome = new FormControl('', [Validators.required]);
    this.email = new FormControl('', [Validators.required]);

    this.senha = new FormControl('', [Validators.required]);
    this.id_situacao = new FormControl('', [Validators.required]);
    this.id_perfil = new FormControl('', [Validators.required]);
    this.forcar_troca = new FormControl('');

    this.form = this.formBuilder.group({
      id_usuario: this.id_usuario,
      nome: this.nome,
      email: this.email,
      senha: this.senha,
      id_situacao: this.id_situacao,
      id_perfil: this.id_perfil,
      forcar_troca: this.forcar_troca

    });

    this.buscarPerfils()

    /*  this.form = this.formBuilder.group({
        nm_tela: ['', Validators.required],
        icone: ['', Validators.required],
        url: ['', Validators.required]     
      })*/
  }


  onSubmit() {

    const payload = {
      token: this.token,
      idUsuario: this.idUsuario
    }
    console.log(this.form.value);
    this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
      .subscribe(e => {
        var tempKey = e.data;
        var keyPadrao = this.apiCripto.getKeyPadrao();
        var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));
        var hash = CryptoJS.SHA256(this.form.value.senha).toString(CryptoJS.enc.Hex);
        const payloadTela = {
          token: this.token,
          idUsuario: this.idUsuario,
          nome: this.apiCripto.encryptUsingAES256Random(this.form.value.nome.toString(), keyUser),
          senha: this.apiCripto.encryptUsingAES256Random(hash, keyUser),
          id_situacao: this.apiCripto.encryptUsingAES256Random(this.form.value.id_situacao.toString(), keyUser),
          id_perfil: this.apiCripto.encryptUsingAES256Random(this.form.value.id_perfil.toString(), keyUser),
          email: this.apiCripto.encryptUsingAES256Random(this.form.value.email.toString(), keyUser),
          forcar_troca: this.apiCripto.encryptUsingAES256Random(this.form.value.forcar_troca.toString(), keyUser),
          id_usuario: this.id
        }
        this.api.updateUsuario(payloadTela)
          .subscribe(res => {
            if (res.status === 'Sucesso') {
              this.toastr.success(res.msg);
              this.router.navigate(['/lst-usuario']);
            }
            else {
              this.toastr.error(res.msg);
            }
          })
      });
  }

  voltar() {
    this.router.navigate(['/lst-usuario']);
  }
}
