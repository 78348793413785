<div class="card card-success">
    <div class="card-header">
        <h3 class="card-title">Filtros</h3>
        <div class="card-tools">
            <!-- <button type="button" class="btn btn-tool" (click)="collapse.toggle()" [attr.aria-expanded]="!gfg"
                aria-controls="collapseExample">
               
                <i *ngIf="!gfg" class="fas fa-minus"></i>
                <i *ngIf="gfg"  class="fas fa-plus"></i>
               
            </button>-->
            
        </div>

    </div>

    <div class="card-body" #collapse="ngbCollapse" [(ngbCollapse)]="gfg">
        <form [formGroup]="form">
            <div class="row">

                <div class="col-md-4">
                    <div class="form-group">
                        <label for="nome">Usuário </label>
                        <input type="text" class="form-control" name="usuario" id="usuario" formControlName="usuario">

                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="nome">E-mail </label>
                        <input type="text" class="form-control" name="email" id="email" formControlName="email">

                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label>Perfil</label>
                        <select class="form-control" name="perfil" id="perfil" formControlName="perfil">
                            <option value="1">Administrador</option>
                            <option value="2">Usuário</option>
                        </select>

                    </div>
                </div>
            </div>











            <button class="btn btn-warning " shape="round" (click)='limpar()'>
                Limpar Filtros
            </button>

            <button class="btn btn-primary ml-3" shape="round" (click)='pesquisar()'>
                Pesquisar
            </button>



        </form>
    </div>

</div>


<div class="card card-primary">
    <div class="card-header">
        <h3 class="card-title">Lista Usuário</h3>
        <button class="btn btn-success float-right " shape="round" (click)='addPerfil()'>
            Adicionar
        </button>
        <div class="card-tools">
            <!-- Buttons, labels, and many other things can be placed here! -->
            <!-- Here is a label for example -->

        </div>
        <!-- /.card-tools -->
    </div>
    <!-- /.card-header -->
    <div class="card-body" >

        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Id Usuário</th>
                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>Perfil</th>
                    <th>Ações</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let registro of tempRelacao | paginate: { itemsPerPage: totalRegistrosExibidos, currentPage: p, totalItems:totalRegistros } ">

                    <td>{{registro.id_usuario}}</td>
                    <td>{{registro.nome}}</td>
                    <td>{{registro.email}}</td>
                    <td>{{registro.nm_perfil}}</td>
                    <td>

                        <button class="btn btn-warning ml-3" (click)="editar(registro)"> Editar</button>
                        <button class="btn btn-danger ml-3" (click)="deletar(registro)"> Apagar</button>

                    </td>
                </tr>

            </tbody>
        </table>
        <div class="h-100 d-flex align-items-center justify-content-center">
            <pagination-controls (pageChange)="pageChanged($event)" previousLabel="Voltar"
                nextLabel="Proximo"></pagination-controls>
        </div>
    </div>


    <!--
    <div class="card-footer clearfix">
        <ul class="pagination pagination-sm m-0 float-right" *ngFor="let paginas of totalPaginas ">
            {{paginas}}
            <li class="page-item"><a class="page-link" href="#">«</a></li>
            <li class="page-item"><a class="page-link" href="#">1</a></li>
            <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item"><a class="page-link" href="#">»</a></li>
        </ul>
    </div>
-->

</div>